/* eslint-disable jsx-a11y/alt-text */
/* global  JitsiMeetJS */

import React from "react";
import ReactDOM from "react-dom";
import { isInStandaloneMode, toPascalCase } from "../../lib/helper";
import Logger from "../../lib/Logger";
import RTCManager from "../../lib/RTCManager";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { createLocalTracksfun, isScreenSharingTrack } from "../../lib/utils";
import ScreenShare from "./ScreenShare";
import ClearIcon from "@material-ui/icons/Clear";
import { withSnackbar } from "notistack";
import "./ButtonBarComponent.css";
import "./CallingComponent.css";
import "./MainView/MainView.css";
import { createRnnoiseProcessor } from "./rnnoise";
import { createPresenterEffect } from "./presenter";
import debounce from "lodash.debounce";

import {
  AUDIO_INPUT_DEVICES,
  AUDIO_OUTPUT_DEVICES,
  COMETCHAT_ACTION_MESSAGE,
  confOptions,
  END_CALL,
  EVENT_MESSAGE,
  HANG_UP,
  initOptions,
  MUTE_AUDIO,
  ON_USER_JOINED,
  ON_USER_LEFT,
  ON_USERLIST_CHANGED,
  ORIENTATION_LANDSCAPE,
  ORIENTATION_PORTRAIT,
  PAUSE_VIDEO,
  SCREEN_SHARE_START,
  SCREEN_SHARE_STOP,
  SCREEN_SHARING_STARTED,
  SCREEN_SHARING_STOPPED,
  TRACK_AUDIO,
  TRACK_VIDEO,
  UNMUTE_AUDIO,
  UNPAUSE_VIDEO,
  VIDEO_INPUT_DEVICES,
  START_SCREEN_RECORDING,
  STOP_SCREEN_RECORDING,
  ON_DEVICE_CHANGE,
  CHANGE_AUDIO_INPUT,
  CHANGE_AUDIO_OUTPUT,
  CHANGE_VIDEO_INPUT,
  INITIAL_DEVICE_LIST,
  SWITCH_MODE,
  ON_MODE_CHANGE,
  START_RECORDING,
  STOP_RECORDING,
  ON_RECORDING_TOGGLED,
  TRYING_TO_START_SCREEN_RECORDING,
  TRYING_TO_STOP_START_SCREEN_RECORDING,
  PARTICIPANT_MUTED,
  LocalizedString,
  ON_USER_MUTED,
  SCREEN_SHARE_STARTED,
  SCREEN_SHARE_ENDED,
  OPEN_VIRTUAL_BACKGROUND_MENU,
  SWITCH_TO_VIDEO_SESSION_RESPONSE,
  SWITCH_TO_VIDEO_SESSION_REQUEST,
  SWITCHED_TO_VIDEO_CALL,
  SWITCH_TO_VIDEO_CALL,
  SET_BACKGROUND_BLUR,
  SET_BACKGROUND_IMAGE,
  NOISY_MIC,
  NOISY_MIC_MUTED,
  videoSettings,
  CALLING_MODES,
  END_SESSION_FOR_ALL,
  CALL_END_BUTTON_PRESSED,
  ON_USER_UNMUTED,
} from "../../Utils/Constants";
import BottomButtons from "./BottomButton/BottomButton";
import Analytics from "./Analytics";
import UserList from "./UserList/UserList";
import Spotlight from "./MainView/SpotLight";
import LocalVideo from "./MainView/LocalVideo";

import { CircularProgress, Typography, Snackbar, IconButton } from "@material-ui/core";
import SideBar from "./MainView/Sidebar";
import Presenter from "./MainView/Presenter";
import { addBackgroundEffect } from "./Utils/AddVirtualBackground";
import { getBase64, setImagesInIndexBD, toDataUrl } from "./Utils/functions";

import Localbase from "localbase";
import { defaultBackgroundImageUrls } from "./backgoundImgUrl";
import RequestModal from "./RequestModal";
import RequestModalEndCall from "./RequestModalEndCall";
import Paginated from "./MainView/Paginated";
import { AudioMixerEffect } from "./audio-mixer/AudioMixerEffect";
import { sharedObject } from "../../Utils/common";

let roomName = "Conference";
let connection = null;
let room = null;
let isJoined = false;
let retries = 0;
let retryTimer;
let recon;

const USER_AVATAR_URL = "avatar-url";
const USER_UID = "uid";
const USER_OBJECT = "user_object";
let TAG = "CallingComponent";
let recordId = "";

class CallingComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.LastNId = [];
    this.stats = {};
    this.showOnLeftMsg = true;
    this.localUserEndsCall = false;
    this.showUserJoinMsg = false;
    this.usersLeftNotiHoldTime = null;
    this.usersLeft = [];
    this.localizedObject = this.props.localizedObject || LocalizedString;
    let name = this.props.username || "Unnamed";
    const localUser = {
      name,
      avatar: this.props.avatar,
      status: "active",
      isVideoMuted: this.props.startVideoMuted,
      isAudioMuted: this.props.startAudioMuted,
      showVideo: true,
      isLocalUser: true,
    };
    this.recordingRetry = 1;
    this.removeInterruptedUsersTimeoutId = null;
    this.deviceChangeCalled = false;
    this.updateLastN = true;
    this.switchedToVideoCall = false;
    this.inputDeviceChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("input");
    this.speakerChangeSupported = JitsiMeetJS.mediaDevices.isDeviceChangeAvailable("output");
    this.audioInputDevicesSet = new Set([]);
    this.allTracks = [];
    this.state = {
      connectionError: false,
      connectionErrorType: "",
      noisyMics: {},
      mySessionId: this.props.sessionID,
      myUserName: this.props.username,
      isBroadcast: this.props.isBroadcast,
      isGridMode: this.props.mode === "GRID",
      isBroadcaster: this.props.isBroadcaster,
      isAudioOnly: this.props.isAudioOnly,
      showSwitchToVideoCallButton: this.props.showSwitchToVideoCallButton,

      mode: this.props.mode,
      showLocalMedia: false,
      status: "active",
      myAvatar: this.props.avatar || localUser.avatar,
      remoteTracks: [],
      users: [localUser],
      exportUserList: [],
      dominantSpeakerId: null,
      isScreenShareOn: false,
      isChatEnabled: false,
      showthumbList: true,
      showLocalStream: true,
      renderSingleMain: false,
      pinnedUserId: null,
      showEndCallButton: this.props.showEndCallButton,
      showVideoPauseButton: this.props.showPauseVideoButton,
      showAudioMuteButton: this.props.showMuteAudioButton,
      showScreenShareButton: this.props.showScreenShareButton,
      defaultLayout: this.props.defaultLayout,
      avatarMode: this.props.avatarMode,
      noMic: false,
      noCamera: false,
      isReconecting: false,
      orientation: "Portrait",
      resolutions: {},
      isRecording: false,
      isMob: RTCManager.isMob(),
      showUserList: false,
      noOfUsersInTile: 2,
      virtualBackground: {},
      selectedBg: 0,
      appliedBg: 0,
      isBackgroundModelVisible: false,
      toVideoRequestModal: false,
      toVideoRequestType: "initial",
      isAnyScreenShareUser: [],
      connectionLost: false,
      noNetwork: false,
      dominantSpeakerList: [],
      fitVideo: videoSettings.fit === "cover" ? true : false,
      endCallDialogVisible: false,
    };

    const JitsiMeetJSCreateLocalTracksOriginal = JitsiMeetJS.createLocalTracks;
    const createLocalTracksCometChat = async (...alls) => {
      const tracks = await JitsiMeetJSCreateLocalTracksOriginal(...alls);
      for (const track of tracks) {
        this.allTracks.push(track);
      }
      window.allTracks = this.allTracks;
      return tracks;
    };
    JitsiMeetJS.createLocalTracks = createLocalTracksCometChat;
    if (sharedObject.isPresenter === false) {
      JitsiMeetJS.createLocalTracks = async () => [];
      // const createLocalTracksTemp = JitsiMeetJS.createLocalTracks;
      // JitsiMeetJS.createLocalTracks = async (...args) => {
      //   const tracks = await createLocalTracksTemp({ devices: ["audio"] });
      //   await tracks[0].mute();
      //   return tracks;
      // };
    }

    this.toVideoRequestResponder = {};
    this.reconnect = this.reconnect.bind(this);
    this.screenShareUserID = undefined;
    this.screenSharersID = [];
    recon = this.reconnect;
    this.db = new Localbase("db");
    // this.db.delete();
  }

  // chatMessageListener = (e) => {
  //   let msg;
  //   try {
  //     msg = JSON.parse(e.data);
  //   } catch {
  //     msg = undefined;
  //   }
  //   // if (msg && msg.type === "chat") {
  //   //   this.setState({
  //   //     isChatEnabled: msg.message,
  //   //   });
  //   // }
  // };

  componentDidMount() {
    //this will trigger when screen orientation of mobile is changed
    window.onresize = () => {
      this.getOrientation();
    };
    this.checkBrowser();
    this.checkIsPWA();
    //this is triggered when the call has not started and there device change
    if (navigator && navigator.mediaDevices) {
      navigator.mediaDevices.ondevicechange = (event) => {
        if (this.state.noCamera && this.state.noMic && !this.deviceChangeCalled && !connection) {
          this.deviceChangeCalled = true;
          this.createLocalTracks();
        }
      };
    }
    // window.addEventListener(EVENT_BEFOREUNLOAD, this.unload);
    // window.onunload = this.unload;
    // window.addEventListener(EVENT_MESSAGE, this.chatMessageListener, false);
    // window.addEventListener(EVENT_UNLOAD, this.unload);
    this.initProps();
    this.createLocalTracks();
    this.init(this.state.mySessionId);
    // this.initializeRemovingInterruptedUsers();
    JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
    // if(this.state.isBroadcast){
    //   this.setState({
    //     showUsers: false,
    //   });
    // }

    if (
      (this.props.EnforceBackgroundBlur === 1 || this.props.EnforceBackgroundBlur === 2) &&
      (this.props.EnforceBackgroundImage == "" ||
        typeof this.props.EnforceBackgroundImage != "string")
    )
      this.setState({
        appliedBg: this.props.EnforceBackgroundBlur,
        selectedBg: this.props.EnforceBackgroundBlur,
      });
    if (this.props.ShowDefaultImages)
      if (
        this.props.EnforceBackgroundImage !== "" &&
        typeof this.props.EnforceBackgroundImage === "string"
      ) {
        // this.storeImagesInLocal([this.props.EnforceBackgroundImage]);
        this.setState({
          appliedBg: this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
            : this.props.SetImages.length + 3,
          selectedBg: this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
            : this.props.SetImages.length + 3,
        });
      } else {
        this.storeImagesInLocal();
      }
    else if (
      this.props.EnforceBackgroundImage !== "" &&
      typeof this.props.EnforceBackgroundImage === "string"
    ) {
      // this.storeImagesInLocal([this.props.EnforceBackgroundImage]);
      this.setState({
        appliedBg: this.props.ShowDefaultImages
          ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
          : this.props.SetImages.length + 3,
        selectedBg: this.props.ShowDefaultImages
          ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
          : this.props.SetImages.length + 3,
      });
    }
    window.addEventListener("online", () => this.setState({ noNetwork: false }));

    window.addEventListener("offline", () => this.setState({ noNetwork: true }));
  }

  toggleFitVideo = () => {
    this.setState({ fitVideo: !this.state.fitVideo });
  };

  checkIsPWA = () => {
    let isPWA = isInStandaloneMode();
    console.log("isPWA", isPWA);
    if (isPWA) {
      this.PWAListener = document.addEventListener("visibilitychange", async () => {
        if (document.visibilityState === "visible") {
          if (
            !this.state.isAudioOnly &&
            this.state?.users?.[0]?.videoTrack &&
            !this.state?.users?.[0]?.isVideoMuted
          ) {
            const options = {
              devices: [TRACK_VIDEO],
            };

            createLocalTracksfun(options)
              .then(async (track) => {
                let newTrack = track[0];

                await room.replaceTrack(this.state.users[0].videoTrack, newTrack);
                await this.state.users[0].videoTrack.dispose();
                this.setState({
                  users: this.state.users.map((user, i) =>
                    i == 0
                      ? {
                          ...user,
                          videoTrack: newTrack,
                          isVideoMuted: false,
                        }
                      : user
                  ),
                });
              })
              .catch((e) => {
                console.log("checkIsPWA error", e);
              });
          }

          if (this.state?.users?.[0]?.audioTrack && !this.state?.users?.[0]?.isAudioMuted) {
            const options = {
              devices: ["audio"],
            };

            createLocalTracksfun(options)
              .then(async (track) => {
                await room.replaceTrack(this.state.users[0].audioTrack, track[0]);
                await this.state.users[0].audioTrack.dispose();
                this.setState({
                  users: this.state.users.map((user, i) =>
                    i == 0
                      ? {
                          ...user,
                          audioTrack: track[0],
                          isAudioMuted: false,
                        }
                      : user
                  ),
                });
              })
              .catch((e) => {
                console.log("checkIsPWA error", e);
              });
          }
        }
      });
    }
  };

  storeImagesInLocal = (additionalImg = []) => {
    additionalImg.forEach((url, i) => {
      toDataUrl(url, (file) => {
        setImagesInIndexBD(this.db, file, "custom", i);
      });
    });
  };

  /**
   * As We only support Safari browser in IOS
   * checkBrowser Check if the OS is IOS and browsr is saffari
   *  if not then alerts users to use safari
   */
  checkBrowser = () => {
    try {
      if (navigator.userAgent.indexOf("like Mac") !== -1) {
        var ua = window.navigator.userAgent;
        var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
        var webkit = !!ua.match(/WebKit/i);
        var iOSSafari = iOS && webkit && !ua.match(/CriOS/i) && !ua.match(/OPiOS/i);

        if (iOS && !iOSSafari) {
          Logger.log(TAG, "[checkBrowser]", { iOS, webkit, iOSSafari });

          this.props.enqueueSnackbar(this.localizedObject["IOS_BROWSER_WARNING"], {
            variant: "error",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
      }
    } catch (error) {
      Logger.log(TAG, "[checkBrowser]", error);
    }
  };

  /**
   * initProps update few states(flags to hide and show component)
   * depending upon the mode that is a passed in props and type of devices
   */
  initProps() {
    try {
      if (this.state.isAudioOnly && !this.state.showSwitchToVideoCallButton) {
        this.setState({ showVideoPauseButton: false });
      }

      if (this.state.mode === "SINGLE" && !RTCManager.isMob()) {
        this.setState({
          showthumbList: false,
          showUserList: false,
          showLocalStream: true,
          renderSingleMain: true,
        });
      } else if (this.state.mode === "SPOTLIGHT" && !RTCManager.isMob()) {
        this.setState({
          showthumbList: false,
          showUserList: false,
          showLocalStream: true,
          renderSingleMain: false,
        });
      } else {
        if (this.state.isBroadcast) {
          this.setState({
            showthumbList: false,
            showUserList: false,
            showAudioMuteButton: false,
            showVideoPauseButton: false,
            showEndCallButton: true,
            showScreenShareButton: false,
          });
        } else {
          this.setState({
            showthumbList: true,
            showUserList: false,
            showLocalStream: false,
            renderSingleMain: false,
          });
        }
      }
    } catch (error) {
      Logger.log(TAG, "[initProps]", error);
    }
  }

  /**
   * sets the orientation depending on the height and width of device
   */
  getOrientation = () => {
    try {
      var orientation =
        window.innerWidth > window.innerHeight ? ORIENTATION_LANDSCAPE : ORIENTATION_PORTRAIT;
      this.setState({ orientation });
    } catch (error) {
      Logger.log(TAG, "[getOrientation]", error);
    }
  };

  componentWillUnmount() {
    clearTimeout(this.removeInterruptedUsersTimeoutId);

    this.db.delete();
    // window.removeEventListener(EVENT_MESSAGE, this.chatMessageListener, false);
    // window.removeEventListener(EVENT_UNLOAD, this.unload);
    // window.removeEventListener(EVENT_BEFOREUNLOAD, this.unload);
  }
  get localUser() {
    return this.state.users[0];
  }
  dominantSpeakers = [];

  /**
   * unload removes all the listenner and disconnects user from call
   */
  unload = async (e) => {
    if (!this.left) {
      this.left = true;
      this.showOnLeftMsg = false;
      try {
        // const { localUser } = this;
        // if (localUser.audioTrack) {
        //   localUser.audioTrack.dispose();
        // }
        // if (localUser.videoTrack) {
        //   localUser.videoTrack.dispose();
        // }
        if (this.state.isScreenShareOn) this.stopScreenShare();
        // if (
        //   this.state.isRecording &&
        //   this.state.recorder &&
        //   this.state.recorder.id === this.state.users[0].id
        // )
        //   this.stopRecording();
        await room.leave().then(async() => {
        await this.disposeLocalTracks();
          connection.disconnect();
          roomName = "Conference";
          connection = null;
          room = null;
          isJoined = false;
          retries = 0;
          retryTimer = undefined;
          recon = undefined;
          ReactDOM.unmountComponentAtNode(sharedObject.htmlElement);
        });
        this.disposeLocalTracks();
        this.deleteAllTracks();
        (e || window.event).returnValue = null;

        return null;
      } catch (error) {
        Logger.log(TAG, "[unload]", error);
        return null;
      }
    }
    return null;
  };

  deleteAllTracks() {
    for (const track of this.allTracks) {
      if (track.track.readyState !== "ended") {
        track.track.stop();
      }
    }
  }

  /**
   * Initailing the lib jitsi meet
   */
  init = (sessionID) => {
    try {
      roomName = sessionID;
      JitsiMeetJS.init(initOptions);
      JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR);
      //this.makeConnection();
    } catch (error) {
      Logger.log(TAG, "[init]", error);
    }
  };

  /**
   * connecting to the backend server
   */
  makeConnection() {
    try {
      const options = {
        hosts: {
          domain: this.getDomain(),
          muc: this.getMUC(),
          focus: this.getFocus(),
        },
        serviceUrl: this.getWebSocket(),
        bosh: this.getBosh(),
        clientNode: "http://jitsi.org/jitsimeet",
      };

      Logger.log(TAG, "Make connection options = ", options);
      Logger.log(TAG, "Connection object = ", connection);
      Logger.log(TAG, "Connection user object = ", this.props.user);
      if (this.props.user && this.props.user.jwt) {
        connection = new JitsiMeetJS.JitsiConnection(null, this.props.user.jwt, options);
      } else {
        const urlparams = new URLSearchParams(window.location.search);
        const jwt = urlparams.get("jwt");
        // console.log("jwt", jwt);
        connection = new JitsiMeetJS.JitsiConnection(null, jwt ?? null, options);
      }
      connection.connect();
      this.addEventListener();
    } catch (error) {
      Logger.log(TAG, "[makeConnection]", error);
    }
  }

  /**
   *  returns the current domain
   *  @returns {String}
   */
  getDomain() {
    var domain = this.props.domain;
    Logger.log(TAG, "RTC Domain = ", domain);
    return domain;
  }

  /**
   *  returns the MUC link which user need to connect
   *  @returns {String}
   */
  getMUC() {
    var muc = "conference." + this.props.domain;
    Logger.log(TAG, "RTC MUC = ", muc);
    return muc;
  }

  /**
   *  returns the focus link which user need to connect
   *  @returns {String}
   */
  getFocus() {
    var focus = "focus." + this.props.domain;
    Logger.log(TAG, "RTC Focus = ", focus);
    return focus;
  }

  /**
   *  returns the BOSH URL which user need to connect
   *  @returns {String}
   */
  getBosh() {
    var bosh = "https://xmpp." + this.props.domain + "/http-bind";
    Logger.log(TAG, "RTC Bosh = ", bosh);
    return bosh;
  }

  /**
   *  returns BOSH the URL which user need to connect(Legacy)
   *  @returns {String}
   */
  getBoshLegacy() {
    var bosh = "https://" + this.props.domain + "/http-bind";
    Logger.log(TAG, "RTC Bosh = ", bosh);
    return bosh;
  }

  /**
   *  returns wesocket URL which user need to connect(Legacy)
   *  @returns {String}
   */
  getWebSocket() {
    var websocket = "wss://xmpp." + this.props.domain + "/xmpp-websocket";
    Logger.log(TAG, "RTC websocket = ", websocket);
    return websocket;
  }

  /**
   * all the jitsi meet events are listenned in addEventListener
   */
  addEventListener() {
    try {
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionSuccess
      );
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_FAILED,
        this.onConnectionFailed
      );
      connection.addEventListener(
        JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
        this.onDisconnect
      );
    } catch (error) {
      Logger.log(TAG, "[addEventListener]", error);
    }
  }

  muteNoisyMic = () => {
    let users = this.state.users;
    if (users[0].audioTrack) {
      users[0].audioTrack.mute();
      users[0].isAudioMuted = true;
      if (this.props.triggerEvent) {
        this.props.triggerEvent(ON_USER_MUTED, {
          muted: this.removeAudioVideoTrackFromUser(users[0]),
          mutedBy: this.removeAudioVideoTrackFromUser(users[0]),
        });
      }
      this.setState({ users });
      if (this.state.noisyMics[users[0].id] === true) {
        room.sendCommandOnce(NOISY_MIC_MUTED, {
          value: users[0].id,
        });
      }
    }
  };

  unMuteMic = () => {
    let users = this.state.users;
    if (users[0].audioTrack) {
      users[0].audioTrack.unmute();
      users[0].isAudioMuted = false;
      this.setState({ users });
      if (this.props.triggerEvent) {
        this.props.triggerEvent(ON_USER_UNMUTED, {
          muted: this.removeAudioVideoTrackFromUser(users[0]),
          mutedBy: this.removeAudioVideoTrackFromUser(users[0]),
        });
      }
    }
  };

  noisyMicAction = (snackbarId) => (
    <>
      <button
        style={{
          paddingRight: 10,
          outline: "none",
          backgroundColor: "#fff",
          color: "#000",
          fontSize: 11,
          padding: "8px 10px",
          borderRadius: 5,
        }}
        onClick={() => {
          this.muteNoisyMic();
          this.props.closeSnackbar(snackbarId);
        }}
      >
        Mute{"     "}
      </button>
    </>
  );

  talkWhileMuteAction = (snackbarId) => (
    <>
      <button
        style={{
          paddingRight: 5,
          outline: "none",
          backgroundColor: "#fff",
          color: "#000",
          fontSize: 11,
          padding: "8px 10px",
          borderRadius: 5,
        }}
        onClick={() => {
          this.unMuteMic();
          this.props.closeSnackbar(snackbarId);
        }}
      >
        Unmute{"     "}
      </button>
    </>
  );
  /**
   * Called if the connection is made
   */
  onConnectionSuccess = () => {
    try {
      if (this.state.connectionError)
        this.setState({ connectionError: false, connectionErrorType: "" });
      room = connection.initJitsiConference(roomName, {
        ...confOptions,
        createVADProcessor: createRnnoiseProcessor,
      });

      window.room = room;
      Logger.log(TAG, "onConnectionSuccess Called......", room);
      this.getDevices(false);
      const myUserID = room.myUserId() || "";
      const users = [...this.state.users];
      const localUser = users[0];

      users[0] = {
        ...localUser,
        id: myUserID,
      };
      this.setState({
        users,
        dominantSpeakerId: room.lastDominantSpeaker || myUserID,
      });
      room.on(JitsiMeetJS.events.conference.TRACK_ADDED, this.onRemoteTrack);
      room.on(JitsiMeetJS.events.conference.NOISY_MIC, this.onNoisyMic);
      room.on(JitsiMeetJS.events.conference.TALK_WHILE_MUTED, this.onTalkWhileMuted);

      room.on(JitsiMeetJS.events.conference.TRACK_REMOVED, this.onRemoteTrackRemoved);

      room.on(JitsiMeetJS.events.conference.CONFERENCE_JOINED, this.onConferenceJoined);

      room.on(JitsiMeetJS.events.conference.CONFERENCE_FAILED, this.onConferenceFailed);
      room.on(JitsiMeetJS.events.conference.USER_JOINED, this.onUserJoined);
      room.on(JitsiMeetJS.events.conference.USER_LEFT, this.onUserLeft);

      room.on(JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED, this.onTrackMuted);
      room.on(JitsiMeetJS.events.conference.PARTICIPANT_KICKED, () => {});
      room.on(
        JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED,
        this.onDominantSpeakerChanged
      );
      room.on(JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED, this.onRemoteStatsUpdated);
      room.on(JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED, this.onLocalStatsUpdated);
      room.on(JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED, this.onLastNChanged);

      room.on(JitsiMeetJS.events.conference.CONNECTION_INTERRUPTED, this.onConnectionInterrupted);

      room.on(JitsiMeetJS.events.conference.CONNECTION_RESTORED, this.onConnectionRestored);

      //room.on(JitsiMeetJS.events.conference.NOISY_MIC, () => {});
      room.on(
        JitsiMeetJS.events.conference.PARTICIPANT_CONN_STATUS_CHANGED,
        this.onParticipantStatusChange
      );

      JitsiMeetJS.mediaDevices.addEventListener(
        JitsiMeetJS.events.mediaDevices.DEVICE_LIST_CHANGED,
        this.onDeviceListChange
      );

      room.addCommandListener(NOISY_MIC, ({ value }) => {
        this.setState(
          {
            noisyMics: { ...this.state.noisyMics, [value]: true },
          },
          () => {
            setTimeout(() => {
              this.setState({
                noisyMics: { ...this.state.noisyMics, [value]: false },
              });
            }, 15000);
          }
        );
      });

      room.addCommandListener(NOISY_MIC_MUTED, ({ value }) => {
        this.setState({
          noisyMics: { ...this.state.noisyMics, [value]: false },
        });
      });

      room.addCommandListener(SCREEN_SHARING_STARTED, ({ value }) => {
        let temp = JSON.parse(value);
        this.setState({ pinnedUserId: temp.userId });
        this.screenShareUserID = temp.userId;
        this.screenSharersID = [...this.screenSharersID, temp];

        //room.pinParticipant(value);
      });
      room.addCommandListener(START_SCREEN_RECORDING, ({ attributes }) => {
        if (this.state.isRecording === false) {
          this.setState({
            recorder: attributes,
            recordId: attributes.recordId,
            isRecording: true,
          });
          if (this.props.triggerEvent) {
            this.props.triggerEvent(ON_RECORDING_TOGGLED, {
              recordingStarted: true,
              user: this.removeAudioVideoTrackFromUser(attributes),
            });
          }
        }
      });

      room.addCommandListener(TRYING_TO_START_SCREEN_RECORDING, ({ attributes }) => {
        this.setState({ tryingToStartRecording: true, recorder: attributes });
      });
      room.addCommandListener(TRYING_TO_STOP_START_SCREEN_RECORDING, ({ attributes }) => {
        this.setState({
          tryingToStartRecording: false,
          recorder: null,
        });
      });
      room.addCommandListener(PARTICIPANT_MUTED, ({ attributes }) => {
        if (this.showUserJoinMsg) {
          let participantMutedBy = {};
          let mutedParticipant = {};
          this.state.users.map((item) => {
            if (item.id == attributes.mutedParticipantId) {
              mutedParticipant = item;
            } else if (item.id == attributes.participantMutedBy) {
              participantMutedBy = item;
            }
          });

          if (sharedObject.isPresenter !== false) {
            this.props.enqueueSnackbar(
              ` ${toPascalCase(participantMutedBy.name)} ${
                this.localizedObject["MUTED"]
              } ${toPascalCase(mutedParticipant.name)}`,
              {
                variant: "info",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }
            );
          }
          if (this.props.triggerEvent) {
            this.props.triggerEvent(ON_USER_MUTED, {
              muted: this.removeAudioVideoTrackFromUser(mutedParticipant),
              mutedBy: this.removeAudioVideoTrackFromUser(participantMutedBy),
            });
          }
        }
      });

      room.addCommandListener(STOP_SCREEN_RECORDING, ({ attributes }) => {
        if (this.props.triggerEvent) {
          this.props.triggerEvent(ON_RECORDING_TOGGLED, {
            recordingStarted: false,
            user: attributes,
          });
        }
        this.setState({
          recorder: {},
          recordId: null,
          isRecording: false,
        });
      });

      room.addCommandListener(SCREEN_SHARING_STOPPED, ({ value }) => {
        if (value === this.state.pinnedUserId) {
          //room.pinParticipant(null);
          this.setState({
            pinnedUserId: null,
          });
        }
        this.screenShareUserID = undefined;
        this.screenSharersID = this.screenSharersID.filter((item) => item.userId !== value);
      });

      // room.on(
      //   JitsiMeetJS.events.conference.TRACK_AUDIO_LEVEL_CHANGED,
      //   (userID, audioLevel) => Logger.log(`${userID} - ${audioLevel}`)
      // );
      // room.on(JitsiMeetJS.events.conference.PHONE_NUMBER_CHANGED, () =>Logger.log(`${room.getPhoneNumber()} - ${room.getPhonePin()}`));
      // room.on(JitsiMeetJS.events.conference.DISPLAY_NAME_CHANGED,(userID, displayName) => console.log(`${userID} - ${displayName}`));
      room.setDisplayName(this.state.myUserName);

      room.sendCommand(USER_UID, { value: this.props.uid });
      if (this.props.avatar) {
        room.sendCommand(USER_AVATAR_URL, {
          value: this.props.avatar,
        });
      }
      room.addCommandListener(USER_AVATAR_URL, (data, from) => {
        Logger.log(TAG, "Avatar data received : ", data);
        const users = this.state.users.map((user) => {
          if (user.id === from) {
            return {
              ...user,
              avatar: data.value,
            };
          }
          return user;
        });

        this.setState({
          users,
        });
      });
      room.on(JitsiMeetJS.events.conference.SUBJECT_CHANGED, this.onRoomSubjectChange);

      // setTimeout(() => {
      room.addCommandListener(SWITCH_TO_VIDEO_SESSION_REQUEST, (data, from) => {
        let initiator = data.attributes.initiator && JSON.parse(data.attributes.initiator);
        if (from !== this.localUser.id && !this.switchedToVideoCall)
          this.setState({
            toVideoRequestModal: true,
            toVideoRequestInitiator: initiator,
          });
      });

      room.addCommandListener(SWITCH_TO_VIDEO_SESSION_RESPONSE, (data, from) => {
        // console.log("custom", SWITCH_TO_VIDEO_SESSION_RESPONSE, data);
        if (this.switchedToVideoCall) {
          return;
        }
        let responder = data.attributes.responder && JSON.parse(data.attributes.responder);
        console.log("responder", responder);
        if (data.attributes.response == "true") {
          this.switchToVideoCall(true, responder);
          this.switchedToVideoCall = true;
          this.toVideoRequestResponder = responder;
          this.setState(
            {
              requestingForVideoCall: false,
            },
            () => {}
          );
        } else {
          console.log("Reciepient decline the request");
          this.setState({ requestingForVideoCall: false });
          if (sharedObject.isPresenter !== false) {
            this.props.enqueueSnackbar(
              ` ${this.localizedObject["REQUESTING_TO_SWITCH_VIDEO_CALL_REJECTED"]}`,
              {
                variant: "error",
                autoHideDuration: 2000,
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
              }
            );
          }
        }
      });
      // }, 3000);

      const initialConfig = {
        start_recording_on_call_start: this.props.startRecordingOnCallStart,
        is_video_muted: this.props.startVideoMuted,
        is_audio_muted: this.props.startAudioMuted,
        session_id: this.props.sessionID,
        is_audio_only: this.props.isAudioOnly,
        mode: this.props.mode,
        uid: users[0].uid,
        platform: "web",
        sdk: "react",
        app_id: this.props.appID,
        meeting_id: room.xmpp.getJid(),
      };
      if (this.props.urlVersion >= "5")
        initialConfig.call_version = require("../../../package.json").version;
      console.log("initialConfig", initialConfig);
      const urlparams = new URLSearchParams(window.location.search);
      initialConfig.param_v = urlparams.get("v") ?? 0;

      room.sendCommand("initial_config", {
        attributes: initialConfig,
      });
      room.sendCommand(USER_OBJECT, {
        attributes: {
          uid: this.props.uid,
          avatar: this.props.avatar || localUser.avatar,
          name: this.state.myUserName,
          joinnedAt: Date.now(),
          isPresenter: sharedObject.isPresenter,
        },
      });
      room.addCommandListener(USER_OBJECT, (data, from) => {
        Logger.log(TAG, "USER_OBJECT received data : ", data);
        let isPresenter = data.attributes.isPresenter;
        if (data.attributes.uid === "SCREEN_SHARE") {
          return false;
        }
        const users = this.state.users.map((user) => {
          if (user.id === from) {
            if (!user.uid) {
              if (this.props.triggerEvent) {
                this.props.triggerEvent(ON_USER_JOINED, data.attributes);
              }
            }
            return {
              ...user,
              uid: data.attributes.uid,
              joinedAt: data.attributes.joinnedAt,
              isPresenter:
                typeof isPresenter === "string"
                  ? isPresenter === "true"
                    ? true
                    : false
                  : undefined,
            };
          }
          return user;
        });

        var exportUserList = this.state.exportUserList;
        if (!this.state.exportUserList.find((eUser) => eUser.id === from)) {
          const eUser = {
            id: from,
            name: data.attributes.name,
            avatar: data.attributes.avatar,
            uid: data.attributes.uid,
          };
          exportUserList = [...exportUserList, eUser];
          if (this.props.triggerEvent) {
            this.props.triggerEvent(ON_USERLIST_CHANGED, exportUserList);
          }
        }

        this.setState(
          {
            users,
            exportUserList,
          },
          () => {
            Logger.log(TAG, "Export userList = ", this.state.exportUserList);
          }
        );
      });

      room.addCommandListener(END_SESSION_FOR_ALL, (_data) => {
        this.endCall();
      });

      //room.setReceiverVideoConstraint(360);
      room.join();
      connection.removeEventListener(
        JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
        this.onConnectionSuccess
      );
    } catch (error) {
      Logger.log(TAG, "[onConnectionSuccess]", error);
    }
  };

  onCallConvertedToVideo = (initiator, responder) => {
    if (!this.switchedToVideoCall) {
      this.toVideoRequestResponder = responder;
      this.setState({
        toVideoRequestInitiator: initiator,
        toVideoRequestType: "other",
        toVideoRequestModal: true,
      });
    }
  };

  /**
   * Called when JitsiMeetJS.events.connectionQuality.LOCAL_STATS_UPDATED  event is triggered
   * it update the stats of the local user and resolution of all the users
   * @param {Object}
   */
  onLocalStatsUpdated = (stats) => {
    try {
      if (!this.stats["connectionQuality"]) {
        this.stats["connectionQuality"] = {};
      }
      this.stats["connectionQuality"][this.localUser.id] = stats.connectionQuality;
      // this.stats["framerate"] = stats.framerate;
      // this.stats["resolution"] = stats.resolution;

      if (JSON.stringify(this.state.stats) != JSON.stringify(this.stats)) {
        Logger.log(TAG, "onLocalStatsUpdated updated : ", stats);
        this.setState({
          connectionQuality: { ...this.stats["connectionQuality"] },
        });
      }
    } catch (error) {
      Logger.log(TAG, "[onLocalStatsUpdated]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.connectionQuality.REMOTE_STATS_UPDATED  event is triggered
   * it update the stats of the other users in the call
   * @param {string,object}
   */
  onRemoteStatsUpdated = (id, stats) => {
    try {
      if (!this.stats["connectionQuality"]) {
        this.stats["connectionQuality"] = {};
      }

      this.stats["connectionQuality"][id] = stats.connectionQuality;

      if (
        JSON.stringify(this.state.connectionQuality) !=
        JSON.stringify(this.stats["connectionQuality"])
      ) {
        Logger.log(TAG, "onRemoteStatsUpdated updated : ", stats);
        this.setState({
          connectionQuality: { ...this.stats["connectionQuality"] },
        });
      }
    } catch (error) {
      Logger.log(TAG, "[onRemoteStatsUpdated]", error);
    }
  };

  /**
   * called when we are unabl;e to connect to websocket
   * It trys again to reconnect
   */
  onConnectionFailed(e) {
    Logger.log(TAG, "onConnectionFailed Called......", e);
    recon();
  }

  onConferenceFailed = (error) => {
    Logger.log(TAG, "onConferenceFailed Called......", error);
    recon();
  };
  /**
   * tries to recoonect back in an interval of 5s
   * maximum no of try is 10
   */
  reconnect() {
    try {
      Logger.log(TAG, "Reconnect called ...", this.state.users, this.state.connectionError);
      if (!this.state.connectionError) {
        isJoined = false;
        this.setState({ connectionError: true, connectionErrorType: "jwt" });
      }
      retryTimer = setInterval(() => {
        if (retries < 10) {
          Logger.log(TAG, "Reconnect attempt ", retries);
          retries = retries + 1;
          if (navigator.onLine) {
            Logger.log(TAG, "Connection object = ", connection);
            connection.connect();
            retries = 0;
            clearInterval(retryTimer);
          }
        } else {
          clearInterval(retryTimer);
        }
      }, 5000);
    } catch (error) {
      Logger.log(TAG, "[reconnect]", error);
    }
  }

  //TODO: uncomment THIS
  onDisconnect() {
    Logger.log(TAG, "onDisconnect Called......");
    // connection.removeEventListener(
    //   JitsiMeetJS.events.connection.CONNECTION_ESTABLISHED,
    //   this.onConnectionSuccess
    // );
    // connection.removeEventListener(
    //   JitsiMeetJS.events.connection.CONNECTION_FAILED,
    //   this.onConnectionFailed
    // );
    // connection.removeEventListener(
    //   JitsiMeetJS.events.connection.CONNECTION_DISCONNECTED,
    //   this.onDisconnect
    // );
  }

  /**
   * Called when JitsiMeetJS.events.conference.LAST_N_ENDPOINTS_CHANGED  event is triggered
   * it update the last active users it adds a to user object showVideo
   * which will help to detimine wather we need to diplay then on top
   * @param {array,array}
   */
  onLastNChanged = (leavingIds, enteringIds) => {
    try {
      Logger.log(TAG, "LastN LeavingIDs...  ", leavingIds);
      Logger.log(TAG, "LastN enteringIds...  ", enteringIds);
      let tempLastN = []; //before checking leaving id
      let newTempLastN = []; //after checking leaving id
      tempLastN = Array.from(new Set([...enteringIds, ...this.LastNId])); //adding current and new entering id
      if (leavingIds?.length) {
        for (let i = 0; i < tempLastN.length; i++) {
          if (!leavingIds.includes(tempLastN[i])) {
            newTempLastN.push(tempLastN[i]);
          }
        }
      } else {
        newTempLastN = tempLastN;
      }
      console.log("lastN compare", {
        enteringIds,
        leavingIds,
        newTempLastN,
        LastNId: this.LastNId,
      });
      if (!this.state.LastNId) {
        //if there is no lastN we set the lastN directly
        this.LastNId = newTempLastN;
        this.setState({ LastNId: this.LastNId });
      } else if (newTempLastN.toString() != this.LastNId.toString()) {
        //else we compare the previous and current lastN before updating
        this.LastNId = newTempLastN;
        this.setState({ LastNId: this.LastNId });
      }
    } catch (error) {
      Logger.log(TAG, "[onLastNChanged]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.DOMINANT_SPEAKER_CHANGED  event is triggered
   * it updates the dominantSpeakerId state with the id of dominant speaker
   * @param {String}
   */
  onDominantSpeakerChanged = (id) => {
    try {
      Logger.log(TAG, "onDominantSpeakerChanged id ...  ", id);
      if (!id) {
        return;
      }
      if (this.state.users[0].id !== id) {
        let arr = [id, ...this.state.dominantSpeakerList];
        let newSet = new Set(arr);
        this.setState({
          dominantSpeakerId: id,
          dominantSpeakerList: Array.from(newSet),
        });
      }
    } catch (error) {
      Logger.log(TAG, "[onDominantSpeakerChanged]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.USER_JOINED  event is triggered
   * whenever a new user is joined in the conferance this event is triggered
   * @param {String,Object}
   */

  onUserJoined = (id, remoteUser) => {
    try {
      Logger.log(TAG, "onUserJoined", remoteUser);
      var username;
      if (remoteUser.getDisplayName()) {
        username = remoteUser.getDisplayName();
      } else {
        username = "Unnamed";
      }
      if (username === "Unnamed" && remoteUser._statsID.includes("jibri")) {
        if (sharedObject.isPresenter !== false) {
          this.props.enqueueSnackbar(this.localizedObject["CALL_RECORDING_STARTED_MSG"], {
            variant: "info",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
        return false;
      }
      const user = {
        id: remoteUser.getId(),
        name: username,
        avatar: undefined,
        status: remoteUser._connectionStatus,
        isAudioMuted: undefined,
        isVideoMuted: undefined,
        videoTrack: undefined,
        audioTrack: undefined,
      };
      // user.avatar = Helper.getSVGAvatar(
      //   user.name ? user.name : "",
      //   user.name ? user.name.substring(0, 1) : "",
      //   this.state.avatarMode === "fill" || this.state.avatarMode === "square"
      //     ? "square"
      //     : "circle"
      // );

      if (user.name && user.name.includes(LocalizedString.SCREEN_SHARE_POSTFIX)) {
        this.setState({
          isAnyScreenShareUser: [...this.state.isAnyScreenShareUser, user],
        });
      }
      if (sharedObject.mode !== CALLING_MODES.PRESENTER && this.showUserJoinMsg) {
        if (sharedObject.isPresenter !== false) {
          this.props.enqueueSnackbar(
            `${toPascalCase(username)} ${this.localizedObject["USER_JOINED_CALL_MSG"]}`,
            {
              variant: "info",
              autoHideDuration: 2000,
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
            }
          );
        }
      }
      this.setState({
        users: [...this.state.users, user],
      });

      if (this.state.isRecording && this.state.recorder.id === this.state.users[0].id) {
        room.sendCommandOnce(START_SCREEN_RECORDING, {
          attributes: { recordId: this.state.recordId, ...this.state.recorder },
        });
      }
      if (this.state.tryingToStartRecording) {
        room.sendCommandOnce(TRYING_TO_START_SCREEN_RECORDING, {
          attributes: { ...this.state.recorder },
        });
      }
    } catch (error) {
      Logger.log(TAG, "[onUserJoined]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.USER_LEFT  event is triggered
   * whenever a  user is leaves  the conferance this event is triggered
   * @param {String}
   */
  onUserLeft = (id, user) => {
    try {
      Logger.log(TAG, "[onUserLeft]", user._statsID, user._statsID.includes("jibri"));
      if (
        (user.getDisplayName() === "Unnamed" || !user.getDisplayName()) &&
        user._statsID &&
        user._statsID.includes("jibri")
      ) {
        if (sharedObject.isPresenter !== false) {
          this.props.enqueueSnackbar(this.localizedObject["CALL_RECORDING_ENDED_MSG"], {
            variant: "info",
            autoHideDuration: 2000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          });
        }
        // room.sendCommandOnce(STOP_SCREEN_RECORDING, {});

        return false;
      }
      if (this.state.pinnedUserId && user._id === this.state.pinnedUserId) {
        this.setState({ pinnedUserId: "" });
      }

      let smallestJoinedAt = null;
      this.state.users.map((u) => {
        if (
          (smallestJoinedAt == null || smallestJoinedAt.joinedAt > u.joinedAt) &&
          user._id !== u.id &&
          u.status === "active"
        ) {
          smallestJoinedAt = u;
        }
        if (user._id === u.id) {
          if (this.state.tryingToStartRecording) {
            room.sendCommandOnce(TRYING_TO_STOP_START_SCREEN_RECORDING, {});
          }

          if (sharedObject.mode !== CALLING_MODES.PRESENTER && this.showOnLeftMsg) {
            if (u.name && u.name.includes(LocalizedString.SCREEN_SHARE_POSTFIX)) {
              let screenShareUser = [...this.state.isAnyScreenShareUser];
              let newScreenShareUser = screenShareUser.filter((userObj) => {
                return userObj.id != u.id;
              });
              this.setState({ isAnyScreenShareUser: newScreenShareUser });
            }
            if (this.usersLeftNotiHoldTime) {
              clearTimeout(this.usersLeftNotiHoldTime);
            }
            this.usersLeftNotiHoldTime = setTimeout(() => {
              for (let i = 0; i < this.usersLeft.length; i += 4) {
                const chunk = this.usersLeft.slice(i, i + 4);
                const message =
                  chunk.length <= 1
                    ? `${toPascalCase(u.name)} ${this.localizedObject["USER_LEFT_CALL_MSG"]}`
                    : this.localizedObject["USERS_LEFT_CALL_MSG"]
                        .replace("__USERS__", chunk.slice(0, -1).join(", "))
                        .replace("__USER__", chunk[chunk.length - 1]);
                if (sharedObject.isPresenter !== false) {
                  this.props.enqueueSnackbar(message, {
                    variant: "info",
                    autoHideDuration: Math.min(500 + message.length * 90, 3000),
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  });
                }
              }
              this.usersLeftNotiHoldTime = null;
              this.usersLeft = [];
            }, 300);
          }
          this.usersLeft.push(toPascalCase(u.name));
          if (this.props.triggerEvent) {
            this.props.triggerEvent(ON_USER_LEFT, { uid: u.uid, name: u.name, avatar: u.avatar });
          }
        }
      });

      let usersData = this.state.users.filter((u) => u.id !== user._id);
      if (this.state.recorder && user._id === this.state.recorder.id) {
        this.setState({ recorder: smallestJoinedAt });
      }
      let eul = this.state.exportUserList.filter((eUser) => eUser.id !== user._id);
      if (this.props.triggerEvent) {
        this.props.triggerEvent(ON_USERLIST_CHANGED, eul);
      }
      if (usersData.length <= 1) {
        this.props.triggerEvent(HANG_UP, {});
        // setTimeout(()=>{
        // this.onEndCallButtonCLicked(true);
        // }, 1000)
      }
      this.setState({
        exportUserList: eul,
        users: usersData,
      });
    } catch (error) {
      Logger.log(TAG, "[onUserLeft]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.TRACK_MUTE_CHANGED  event is triggered
   * whenever a track in the conferance is muted this event is called
   * @param {Object}
   */
  onTrackMuted = (track) => {
    try {
      Logger.log(TAG, "onTrackMuted ...... ", track);
      Logger.log(TAG, "onTrackMuted isMuted ...... ", track.isMuted());
      let id = track.ownerEndpointId;
      if (track.isLocal()) {
        id = this.state.users[0].id;
      }

      const users = this.state.users.map((user) => {
        if (user.id === id) {
          if (track.getType() === TRACK_VIDEO) {
            return {
              ...user,
              //videoTrack: track,
              isVideoMuted: track.isMuted(),
            };
          }
          return {
            ...user,
            //audioTrack: track,
            isAudioMuted: track.isMuted(),
          };
        }

        return user;
      });

      this.setState({
        users,
      });
    } catch (error) {
      Logger.log(TAG, "[onTrackMuted]", error);
    }
  };
  /**
   * Called when JitsiMeetJS.events.conference.PARTICIPANT_KICKED  event is triggered
   * when ever a user is kicked this event is triggered it checks if kicked user is current user
   * if yes then dissconect the call
   * if no then shows a alert
   * @param {Object}
   */
  onUserKicked = (id, reason) => {
    try {
      let user = room.getParticipantById(id);
      room.kickParticipant(id, "reason");
      Logger.log(TAG, "kickParticipant ......sfddsfdssdff ", user);
    } catch (error) {
      // Logger.log(TAG, "kickParticipant ...... ", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.TRACK_ADDED  event is triggered
   * whenever a track is added by any user in the call this even is triggered
   * @param {object}
   */
  onRemoteTrack = (track) => {
    try {
      Logger.log(TAG, "onRemoteTrack ...... ", track);
      if (track.isLocal()) {
        return;
      }
      const userId = track.ownerEndpointId;
      if (isScreenSharingTrack(track)) {
        this.setState({ pinnedUserId: track.ownerEndpointId });
        //room.pinParticipant(track.ownerEndpointId);
      } else if (room.lastDominantSpeaker === userId) {
        this.setState({ dominantSpeakerId: track.ownerEndpointId });
      }
      const users = this.state.users.map((user) => {
        if (user.id === userId) {
          if (track.type === TRACK_VIDEO) {
            if (isScreenSharingTrack(track))
              return {
                ...user,
                screenShareUserID: this.screenShareUserID,
                videoTrack: track,
                isVideoMuted: track.muted,
              };
            else
              return {
                ...user,
                videoTrack: track,
                isVideoMuted: track.muted,
              };
          } else {
            return {
              ...user,
              audioTrack: track,
              isAudioMuted: track.muted,
            };
          }
        }

        return user;
      });
      this.setState({
        users,
      });
    } catch (error) {
      Logger.log(TAG, "[onRemoteTrack]", error);
    }
  };

  onNoisyMic = (e) => {
    // debugger;
    console.log("9090 NOISY_MIC", e);
    room.sendCommandOnce(NOISY_MIC, {
      value: myUserID,
    });
    if (sharedObject.isPresenter !== false) {
      this.props.enqueueSnackbar(this.localizedObject["NOISY_MIC"], {
        action: this.noisyMicAction,
        variant: "warning",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  onTalkWhileMuted = (e) => {
    if (sharedObject.isPresenter !== false) {
      this.props.enqueueSnackbar(this.localizedObject["TALK_WHILE_MUTED"], {
        action: this.talkWhileMuteAction,
        variant: "warning",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      });
    }
  };

  onConnectionInterrupted = () => {
    isJoined = true;
    this.setState({ connectionLost: true });
  };

  onConnectionRestored = () => {
    isJoined = true;
    this.setState({ connectionLost: false });
  };

  onParticipantStatusChange = (id, status) => {
    Logger.log(TAG, "PARTICIPANT_CONN_STATUS_CHANGED", id, status);
    const users = this.state.users.map((user) => {
      if (user.id === id) {
        return {
          ...user,
          status,
        };
      }

      return user;
    });

    this.setState({ users });
  };

  onDeviceListChange = async (device) => {
    //getting current video device
    let videoId = this.state.users[0].videoTrack && this.state.users[0].videoTrack.deviceId;
    //getting current audio device
    let audioId = this.state.users[0].audioTrack && this.state.users[0].audioTrack.deviceId;
    let audioDevicesPresent = false;
    let videoDevicesPresent = false;
    // checking if audio and video device are present in changed device list
    console.log("onDeviceListChange >>>>", device);
    if (device?.length) {
      device.map((item) => {
        if (item.deviceId === videoId) {
          videoDevicesPresent = true;
        }
        if (item.deviceId === audioId) {
          audioDevicesPresent = true;
        }
      });
      if ((this.state.noCamera || !videoDevicesPresent) && !this.state.isAudioOnly) {
        if (!videoDevicesPresent && !this.state.noCamera) {
          await this.disposeLocalVideoTrack();
        }
        // const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
        // const audioDeviceId = localStorage.getItem("lastAudioDeviceId");
        const options = {
          devices: ["video"],
          facingMode: "user",
        };

        createLocalTracksfun(options)
          .then((t) => {
            this.onLocalTracks(t);
          })
          .catch((error) => {
            Logger.log(TAG, "Error creating Video local media : ", error);
            this.setState({
              noCamera: true,
              showVideoPauseButton: false,
            });
          });
      }
    }
    // if (this.state.noMic || !audioDevicesPresent) {
    //   if (!audioDevicesPresent) {
    //     await this.disposeLocalAudioTrack();
    //   }
    //   JitsiMeetJS.createLocalTracks({
    //     devices: ["audio"],
    //     facingMode: "user",
    //   })
    //     .then((t) => {
    //       this.onLocalTracks(t);
    //       this.setState({ noCamera: false });
    //     })
    //     .catch((error) => {
    //       Logger.log(TAG, "Error creating audio local media : ", error);
    //       this.setState({
    //         noMic: true,
    //         showAudioMuteButton: false,
    //       });
    //     });
    // }
    this.getDevices();
  };

  onRoomSubjectChange = (data) => {
    console.log("SUBJECT_CHANGED", data);
    let subjectData = data ? JSON.parse(data) : {};

    if (subjectData?.converted) {
      let convertedData = subjectData?.converted;
      this.onCallConvertedToVideo(convertedData.initiator, convertedData.responder);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.TRACK_REMOVED  event is triggered
   * whenever a track is removed or disposed by any user in the call this even is triggered
   * @param {object}
   */
  onRemoteTrackRemoved = (track) => {
    try {
      Logger.log(TAG, "onRemoteTrackRemoved ...... ", track);
      const users = this.state.users.map((user) => {
        if (user.id === track.ownerEndpointId) {
          if (track.type === TRACK_VIDEO) {
            return {
              ...user,
              videoTrack: undefined,
            };
          } else {
            return {
              ...user,
              audioTrack: undefined,
            };
          }
        }
        return user;
      });

      this.setState({ users });
    } catch (error) {
      Logger.log(TAG, "[onRemoteTrackRemoved]", error);
    }
  };

  /**
   * Called when JitsiMeetJS.events.conference.CONFERENCE_JOINED  event is triggered
   * it is triggered when we join the conferance succesfully
   */
  onConferenceJoined = async () => {
    try {
      Logger.log("onConferenceJoined called ", this.state.localTracks);

      isJoined = true;

      if (this.props.startRecordingOnCallStart) {
        this.startRecording();
      }
      setTimeout(() => (this.showUserJoinMsg = true), 6000);
      const { localUser } = this;
      if (localUser.audioTrack) {
        room.addTrack(localUser.audioTrack);
      }
      if (localUser.videoTrack) {
        await room.addTrack(localUser.videoTrack);
      }
      const sendCustomEvent = () => {
        room.sendCommandOnce("ON_CONFERENCE_JOINED", {
          attributes: {
            startRecordingOnCallStart: this.props.startRecordingOnCallStart,
            username: this.props.username || "Unnamed",
            avatar: this.props.avatar,
            isVideoMuted: this.props.startVideoMuted,
            isAudioMuted: this.props.startAudioMuted,
            isLegacyServer: false,
            sessionID: this.props.sessionID,
            isAudioOnly: this.props.isAudioOnly,
            mode: this.props.mode,
            uiVersion: 2,
            appID: this.props.appID,
            meeting_id: room.xmpp.getJid(),
          },
        });
      };
      if (typeof room.xmpp?.getJid === "function") {
        sendCustomEvent();
      } else {
        setTimeout(sendCustomEvent, 3000);
      }
    } catch (error) {
      Logger.log(TAG, "[onConferenceJoined]", error);
    }
  };

  /**
   * created the local audio and video track of the users
   */
  createLocalTracks = () => {
    try {
      let singleError = false;

      if (this.state.isAudioOnly) {
        Logger.log(TAG, "createLocalTracks Audio Only", "");
        createLocalTracksfun({
          devices: ["audio"],
        })
          .then(this.onLocalTracks)
          .catch((error) => {
            Logger.log(TAG, "Error creating local media : ", error);
          });
      } else if (this.state.isBroadcast) {
        Logger.log(TAG, "createLocalTracks Audio Only", "");
        createLocalTracksfun({
          devices: ["audio"],
        })
          .then(this.onLocalTracks)
          .catch((error) => {
            Logger.log(TAG, "Error creating local media : ", error);
          });
      } else if (this.state.mode === "SINGLE") {
        Logger.log(TAG, "Single Mode local track create", "");
        // const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
        const options = {
          devices: ["audio", "video"],
          facingMode: "user",
          resolution: 1080,
          constraints: {
            video: {
              aspectRatio: 16 / 9,
              height: { ideal: 720, max: 720, min: 405 },
              width: { ideal: 1280, max: 1280, min: 720 },
            },
          },
        };

        createLocalTracksfun(options)
          .then(this.onLocalTracks)
          .catch((error) => {
            console.log(error);
            Logger.log(TAG, "[createLocalTracks Video & Audio]", "erroraudio", error);

            createLocalTracksfun({
              devices: ["audio"],
              facingMode: "user",
            }).then(this.onLocalTracks);
          });
      } else {
        Logger.log(TAG, "createLocalTracks Video & Audio", "");
        // const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
        const options = {
          devices: ["audio", "video"],
        };
        createLocalTracksfun(options)
          .then(this.onLocalTracks)
          .catch((error) => {
            createLocalTracksfun({
              devices: ["audio"],
              facingMode: "user",
            })
              .then((t) => {
                this.onLocalTracks(t);
                this.props.enqueueSnackbar(this.localizedObject["CAMERA_ERROR_MSG"], {
                  variant: "error",
                });
              })
              .catch((error) => {
                Logger.log(TAG, "Error creating Audio local media : ", error);
                this.setState({ noMic: true, showAudioMuteButton: false });

                singleError = true;
                // const cameraDeviceId =
                //   localStorage.getItem("lastVideoDeviceId");
                // console.log('custom cameraDeviceId', cameraDeviceId);
                const options = {
                  devices: ["video"],
                  facingMode: "user",
                };

                createLocalTracksfun(options)
                  .then((t) => {
                    this.onLocalTracks(t);

                    this.props.enqueueSnackbar(this.localizedObject["MIC_ERROR_MSG"], {
                      variant: "error",
                    });
                  })
                  .catch((error) => {
                    if (singleError) {
                      this.props.enqueueSnackbar(this.localizedObject["CAMERA_MIC_ERROR_MSG"], {
                        variant: "error",
                      });
                    }
                    Logger.log(TAG, "Error creating Video local media : ", error);
                    this.setState({
                      noCamera: true,
                      showVideoPauseButton: false,
                    });
                  });
              });
          });
      }
    } catch (error) {
      Logger.log(TAG, "[createLocalTracks]", error);
    }
  };

  /**
   * when ever a local track is created it passed to onLocalTracks so that we can update local user with that track
   * @param {object,boolean}
   */
  onLocalTracks = (tracks, fromScreenShare) => {
    Logger.log(TAG, "[onLocalTracks]", tracks, fromScreenShare);
    try {
      if (!fromScreenShare && !connection) {
        this.makeConnection();
      }

      tracks.forEach(async (_, i) => {
        if (tracks[i].getType() === TRACK_VIDEO) {
          // console.log("custom TRACK_VIDEO", tracks[i].deviceId);
          localStorage.setItem("lastVideoDeviceId", tracks[i].deviceId);
          const users = [...this.state.users];
          const localUser = users[0];
          if (localUser.isVideoMuted && !fromScreenShare) {
            tracks[i].mute();
          }

          if (
            this.props.EnforceBackgroundImage !== "" &&
            typeof this.props.EnforceBackgroundImage == "string"
          )
            addBackgroundEffect({
              track: tracks[i],
              option: { type: "img", url: this.props.EnforceBackgroundImage },
            });
          else if (this.props.EnforceBackgroundBlur === 1 || this.props.EnforceBackgroundBlur === 2)
            addBackgroundEffect({
              track: tracks[i],
              option: {
                type: "blur",
                blurValue: this.props.EnforceBackgroundBlur === 1 ? 6 : 15,
              },
            });
          users[0] = {
            ...localUser,
            videoTrack: tracks[i],
          };
          this.setState({
            users,

            noCamera: false,
          });
        }
        if (tracks[i].getType() === TRACK_AUDIO) {
          const users = [...this.state.users];
          const localUser = users[0];
          if (localUser.isAudioMuted) {
            tracks[i].mute();
          }
          users[0] = {
            ...localUser,
            audioTrack: tracks[i],
          };
          this.setState({
            users,
            noMic: false,
          });
        }
        if (isJoined) {
          if (tracks[i].getType() === TRACK_AUDIO && room.getLocalAudioTrack()) {
            await room.replaceTrack(room.getLocalAudioTrack(), tracks[i]);
          } else {
            await room.addTrack(tracks[i]);
          }
        }
      });
      if (this.state.isBroadcast) {
        const { localUser } = this;
        localUser.audioTrack.mute();
      }
    } catch (error) {
      Logger.log(TAG, "[onLocalTracks]", error);
    }
  };

  switchToVideoCall = async (sendCommand = false, responder = {}) => {
    this.setState({ isAudioOnly: false });
    // return;
    if (sendCommand) {
      let obj = {
        initiator: {
          ...this.state.toVideoRequestInitiator,
        },
        responder: {
          ...responder,
        },
        response: true,
      };
      room.setSubject(JSON.stringify({ converted: obj }));
    }
    // await this.removeLocalTracks();
    // this.createLocalTracks();
    // const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
    // console.log('custom cameraDeviceId', cameraDeviceId);
    const options = {
      devices: ["video"],
      facingMode: "user",
    };

    JitsiMeetJS.createLocalTracks(options)
      .then((t) => {
        this.onLocalTracks(t);
      })
      .catch((error) => {
        Logger.log(TAG, "Error creating Video local media : ", error);
        this.setState({
          noCamera: true,
          showVideoPauseButton: false,
        });
      });
    if (this.props.triggerEvent) {
      this.props.triggerEvent(SWITCHED_TO_VIDEO_CALL, {
        sessionId: this.props.sessionID,
        initiator: this.state.toVideoRequestInitiator,
        responder: responder,
      });
    }
  };

  sendRequestforVideoCall = (user) => {
    // console.log("custom sendRequestforVideoCall", user);
    this.setState({
      toVideoRequestInitiator: {
        ...user,
        audioTrack: null,
        videoTrack: null,
        stats: null,
      },
    });
    room.sendCommandOnce(SWITCH_TO_VIDEO_SESSION_REQUEST, {
      attributes: {
        initiator: JSON.stringify({
          ...user,
          audioTrack: null,
          videoTrack: null,
          stats: null,
        }),
      },
    });
  };

  sendVideoRequestResponse = (userResponse) => {
    if (this.state.toVideoRequestType == "initial") {
      // console.log("custom sending inital response command");
      room.sendCommandOnce(SWITCH_TO_VIDEO_SESSION_RESPONSE, {
        attributes: {
          responder: JSON.stringify({
            ...this.state.users[0],
            audioTrack: null,
            videoTrack: null,
            stats: null,
          }),
          response: userResponse,
        },
      });
    } else {
      if (userResponse) {
        this.switchToVideoCall();
      } else {
        this.onEndCallButtonCLicked();
      }
    }

    this.setState({ toVideoRequestModal: false });
  };

  /**
   * called when video button is clicked  it mutes/umutes the video track of local user
   */
  toggleVideoButton = async (switchToVideoAction) => {
    if (switchToVideoAction && (!this.state.isAudioOnly || this.switchedToVideoCall)) {
      return;
    }
    let users = this.state.users;
    if (this.state.isAudioOnly && !this.switchedToVideoCall) {
      this.sendRequestforVideoCall(users[0]);
      this.setState({ requestingForVideoCall: true });
      return;
    }
    if (this.presenterTrack) {
      if (!this.state.isPresenterTrackMuted) {
        this.desktopVideoTrack.setEffect(undefined);
        this.presenterTrack.dispose();
        this.setState({ isPresenterTrackMuted: true });
      } else {
        this.setState({ isPresenterTrackMuted: false });
        await this.desktopVideoTrack.setEffect(
          await createPresenterEffect(await this.createLocalPresenterTrack())
        );
      }
      return;
    }
    if (users[0].videoTrack) {
      if (users[0].isVideoMuted) {
        users[0].videoTrack.unmute();
        users[0].isVideoMuted = false;
      } else {
        users[0].videoTrack.mute();
        users[0].isVideoMuted = true;
      }
      this.setState({ users });
    }
  };

  /**
   * called when PAUSE_VIDEO action is called by chat SDK  it mutes the video track of local user
   */
  pauseVideo() {
    const { localUser } = this;
    if (localUser.videoTrack) localUser.videoTrack.mute();
  }

  /**
   * called when UNPAUSE_VIDEO action is called by chat SDK  it unmutes the video track of local user
   */
  unPauseVideo() {
    const { localUser } = this;
    if (localUser.videoTrack) localUser.videoTrack.unmute();
  }

  /**
   * called when audio button is clicked  it mutes/umutes the video track of local user
   */
  toggleAudioButton = () => {
    let users = this.state.users;
    if (users[0].audioTrack) {
      if (users[0].isAudioMuted) {
        users[0].audioTrack.unmute();
        users[0].isAudioMuted = false;
        if (this.props.triggerEvent) {
          this.props.triggerEvent(ON_USER_UNMUTED, {
            muted: this.removeAudioVideoTrackFromUser(users[0]),
            mutedBy: this.removeAudioVideoTrackFromUser(users[0]),
          });
        }
      } else {
        users[0].audioTrack.mute();
        users[0].isAudioMuted = true;
        if (this.props.triggerEvent) {
          this.props.triggerEvent(ON_USER_MUTED, {
            muted: this.removeAudioVideoTrackFromUser(users[0]),
            mutedBy: this.removeAudioVideoTrackFromUser(users[0]),
          });
        }
      }
      this.setState({ users });
      if (this.state.noisyMics[users[0].id] === true) {
        room.sendCommandOnce(NOISY_MIC_MUTED, {
          value: users[0].id,
        });
      }
    }

    // const { localUser } = this;
    // if (localUser.isAudioMuted) {
    //   localUser.audioTrack.unmute();
    // } else {
    //   localUser.audioTrack.mute();
    // }
  };

  /**
   * called when MUTE_AUDIO action is called by chat SDK  it mutes the audio track of local user
   */
  muteAudio() {
    const { localUser } = this;
    if (localUser.audioTrack) localUser.audioTrack.mute();
  }

  /**
   * called when UNMUTE_AUDIO action is called by chat SDK  it unmutes the audio track of local user
   */
  unMuteAudio() {
    const { localUser } = this;
    if (localUser.audioTrack) localUser.audioTrack.unmute();
  }

  endCall = async () => {
    this.setState({ endCallDialogVisible: false });
    await this.unload();
  };

  /**
   * called when end call button is clicked or by chat SDK  it disconnect user from conferance
   */
  onEndCallButtonCLicked = () => {
    this.localUserEndsCall = true;
    if (sharedObject.isPresenter === true && this.state.endCallDialogVisible === false) {
      this.setState({ endCallDialogVisible: true });
      return;
    }
    this.endCall();
  };

  endSessionForAll = () => {
    room.sendCommand(END_SESSION_FOR_ALL, {
      value: this.state.users[0].id,
    });
    this.endCall();
  };

  /**
   * Disposes video and autio track of local user
   */
  async removeLocalTracks() {
    try {
      const users = [...this.state.users];
      const localUser = users[0];
      await room.removeTrack(localUser.audioTrack);
      await room.removeTrack(localUser.videoTrack);
      return;
    } catch (error) {
      Logger.log(TAG, "[disposeLocalTracks]", error);
    }
  }

  async disposeLocalTracks() {
    try {
      const users = [...this.state.users];
      const localUser = users[0];
      if (localUser.audioTrack) {
        await localUser.audioTrack.dispose();
      }
      if (localUser.videoTrack) {
        await localUser.videoTrack.dispose();
      }
      return null;
    } catch (error) {
      Logger.log(TAG, "[disposeLocalTracks]", error);
    }
  }

  /**
   * Disposes video track of local user
   */
  disposeLocalVideoTrack = async () => {
    try {
      const users = [...this.state.users];
      const localUser = users[0];
      if (localUser.videoTrack) {
        await localUser.videoTrack.mute();
        await localUser.videoTrack.dispose();
      }
      users[0] = {
        ...localUser,
        videoTrack: undefined,
      };
      this.setState({
        users,
      });
    } catch (error) {
      Logger.log(TAG, "[disposeLocalVideoTrack]", error);
    }
  };

  /**
   * Disposes audio track of local user
   */
  disposeLocalAudioTrack = async () => {
    try {
      const users = [...this.state.users];
      const localUser = users[0];
      if (room.getLocalAudioTrack()) {
        await room.getLocalAudioTrack().dispose();
      }
      users[0] = {
        ...localUser,
        audioTrack: undefined,
      };
      this.setState({
        users,
      });
    } catch (error) {
      Logger.log(TAG, "[disposeLocalAudioTrack]", error);
    }
  };

  /**
   * Creates a screen share track for the local user
   */
  createScreenShareTrack = async () => {
    //this.setState({ screenShareLoader: true });
    if (this.props.screenShareMode === "presenter") {
      this.createDesktopTrack();
      return;
    }
    this.ScreenShareRef.start();
  };

  createLocalPresenterTrack = async (disposeTrack, desktopHeight = 400, cameraDeviceId) => {
    const cameraHeights = [180, 270, 360, 540, 720];
    const proportion = 5;
    const result = cameraHeights.find((height) => desktopHeight / proportion < height);
    const constraints = {
      video: {
        aspectRatio: 4 / 3,
        height: {
          ideal: result,
        },
      },
    };
    cameraDeviceId = await localStorage.getItem("lastVideoDeviceId");
    console.log("custom cameraDeviceId", cameraDeviceId);
    const options = {
      // cameraDeviceId,
      constraints,
      devices: ["video"],
    };

    const [videoTrack] = await JitsiMeetJS.createLocalTracks(
      cameraDeviceId
        ? {
            ...options,
            cameraDeviceId,
          }
        : options
    );
    videoTrack.type = "presenter";
    videoTrack.isVideoMuted = false;
    this.presenterTrack = videoTrack;
    if (disposeTrack) videoTrack.dispose();
    return videoTrack.stream;
  };

  createDesktopTrack = () => {
    JitsiMeetJS.createLocalTracks({
      devices: ["desktop"],
      desktopSharingSources: ["screen", "window"],
      resolution: 1080,
      constraints: {
        video: {
          aspectRatio: 16 / 9,
          height: { ideal: 720, max: 720, min: 405 },
          width: { ideal: 1280, max: 1280, min: 720 },
        },
      },
    })
      .then(async (tracks) => {
        this.desktopAudioTrack = tracks.find((track) => track.getType() === "audio");
        this.desktopVideoTrack = tracks.find((track) => track.getType() === "video");
        if (this.props.screenShareMode === "presenter")
          this.desktopVideoTrack.track.onended = () => {
            this.stopScreenShare();
          };
        const isLocalVideoMuted = this.state.users[0].isVideoMuted;
        if (isLocalVideoMuted) {
          await this.state.users[0].videoTrack.unmute();
          await room.replaceTrack(this.state.users[0].videoTrack, this.desktopVideoTrack);
          await this.state.users[0].videoTrack.dispose();
          await this.createLocalPresenterTrack(true);
        } else {
          await room.replaceTrack(this.state.users[0].videoTrack, this.desktopVideoTrack);
          await this.state.users[0].videoTrack.dispose();
          await this.desktopVideoTrack.setEffect(
            await createPresenterEffect(await this.createLocalPresenterTrack())
          );
        }

        if (this.desktopAudioTrack) {
          this._mixerEffect = new AudioMixerEffect(this.desktopAudioTrack);
          await this.state.users[0].audioTrack.setEffect(this._mixerEffect);
        }

        room.sendCommand(SCREEN_SHARING_STARTED, {
          value: this.state.users[0].id,
        });
        this.setState({
          users: this.state.users.map((user, i) =>
            i == 0 ? { ...user, videoTrack: this.desktopVideoTrack } : user
          ),
          isScreenShareOn: true,
          isPresenterTrackMuted: isLocalVideoMuted,
        });

        this.screenShareUserID = this.state.users[0].id;
        this.myScreenShareID = this.state.users[0].id;
        // this.setState({ isScreenShareOn: true, screenShareLoader: false });
        if (this.props.triggerEvent) {
          this.props.triggerEvent(SCREEN_SHARE_STARTED, {});
        }
      })
      .catch((error) => {
        console.error("ScreenShare", error);
      });
  };

  /**
   * Stops/disposed a screen share track for the local user
   */
  stopScreenShare = async () => {
    // room.sendCommandOnce(SCREEN_SHARING_STOPPED, {
    //   value: this.screenShareUserID,
    // });
    this.setState({
      isScreenShareOn: false,
    });

    if (this.props.screenShareMode === "presenter") {
      // const cameraDeviceId = await localStorage.getItem("lastVideoDeviceId");
      // console.log("custom cameraDeviceId", cameraDeviceId);
      const options = {
        devices: ["video"],
        facingMode: "user",
      };

      createLocalTracksfun(options)
        .then(async (t) => {
          await room.replaceTrack(this.desktopVideoTrack, t[0]);
          if (this.state.isPresenterTrackMuted) {
            t[0].mute();
            t[0].isVideoMuted = true;
          }
          this.desktopVideoTrack.dispose();
          if (this.presenterTrack) {
            this.presenterTrack.dispose();
            this.presenterTrack = null;
          }
          this.setState({
            users: this.state.users.map((user, i) =>
              i == 0 ? { ...user, videoTrack: t[0] } : user
            ),
            isScreenShareOn: false,
            screenShareLoader: false,
          });
          this.myScreenShareID = null;
          room.sendCommandOnce(SCREEN_SHARING_STOPPED, {
            value: this.screenShareUserID,
          });
          if (this.props.triggerEvent) {
            this.props.triggerEvent(SCREEN_SHARE_ENDED, {});
          }
          // this.onLocalTracks(t);
        })
        .catch((error) => {
          Logger.log(TAG, "Error creating Video local media : ", error);
        });
      return;
    }
    this.ScreenShareRef.stop();
  };

  /**
   * called when Screenshare button is clicked or by chat SDK  it disconnect user from conferance
   */
  onScreenShareClicked = () => {
    if (this.state.isScreenShareOn) {
      this.stopScreenShare();
    } else {
      this.createScreenShareTrack();
    }
  };

  onUserItemClicked = (user) => {
    this.setState({
      dominantSpeakerId: user.id,
    });
  };

  removeAudioVideoTrackFromUser = (user) => {
    let newUser = { ...user };
    delete newUser.audioTrack;
    delete newUser.videoTrack;
    return newUser;
  };

  /**
   * Called when we click on mute for other participants
   * @param {object}
   */
  muteParticipant = (user) => {
    try {
      room.muteParticipant(user.id);
      room.sendCommandOnce(PARTICIPANT_MUTED, {
        attributes: {
          mutedParticipantId: user.id,
          participantMutedBy: this.state.users[0].id,
        },
      });
    } catch (e) {
      Logger.log(TAG, "[muteParticipant]", e);
    }
  };

  /**
   * Called when we click on kick for other participants
   * @param {object}
   */
  kickParticipant = (id) => {
    try {
      let user = room.getParticipantById(id);
      room.kickParticipant(id, "reason");
      Logger.log(TAG, "kickParticipant ......sfddsfdssdff ", user);
      Logger.log(TAG, "[kickParticipant]", id);
      room.kickParticipant(id);
    } catch (e) {
      Logger.log(TAG, "[kickParticipant]", e);
    }
  };

  /**
   * Called when we pin a user in any mode
   * @param {object}
   */
  pinUser = (user) => {
    this.setState({
      pinnedUserId: user.id,
    });
    //room.pinParticipant(user.id);
  };

  /**
   * Called when we UNpin a user in any mode
   */
  unPinUser = () => {
    //room.pinParticipant(null);
    this.setState({
      pinnedUserId: null,
    });
  };

  //TODO needs to modify this
  renderError() {
    if (!this.state.errorMsg) {
      return null;
    }
    return (
      <Snackbar
        variant='error'
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        message={this.state.errorMsg}
        open={true}
        onClose={() => {
          this.setState({ errorMsg: "" });
        }}
        action={
          <IconButton
            component='span'
            onClick={() => {
              this.setState({ errorMsg: "" });
            }}
            color='inherit'
            size='small'
          >
            <ClearIcon />
          </IconButton>
        }
        autoHideDuration={6000}
        key={"top right"}
      ></Snackbar>
    );
  }

  /**
   * Genrated three meadia list for video , audio input and audio output
   * which this saved in state and also passed to CHat SDK
   * Trigged at intialization of app and ondevice change event
   * @param {boolean}
   */
  getDevices = async (sendDeviceList = true) => {
    JitsiMeetJS.mediaDevices.enumerateDevices((devices) => {
      console.log("custom devices", devices);
      let audioDevices = [];
      let audioOutPutDevices = [];
      let videoDevices = [];
      let defaultDevices = {};
      let activeVideoDeviceID = this.state?.users[0]?.videoTrack?.deviceId;

      this.audioInputDevicesSet.forEach((deviceId) => {
        const findItem = devices.find((item) => item.deviceId === deviceId);
        if (!findItem) {
          this.audioInputDevicesSet.delete(deviceId);
        }
      });
      const inputDevices = devices.filter((item) => item.kind === AUDIO_INPUT_DEVICES);

      inputDevices.reverse().forEach((item) => {
        this.audioInputDevicesSet.add(item.deviceId);
      });

      let selectedDevice = [];
      inputDevices.forEach((item) => {
        if (item.deviceId === "default") {
          selectedDevice = inputDevices.filter(
            (item2) => item.groupId === item2.groupId && item2.deviceId !== "default"
          );
        }
      });
      if (selectedDevice?.length !== 0) {
        let lastAudioDeviceId = localStorage.getItem("lastAudioDeviceId");
        if (!lastAudioDeviceId)
          localStorage.setItem("lastAudioDeviceId", selectedDevice[0].deviceId);
      }
      devices.sort((a, b) => b.deviceId.length - a.deviceId.length).reverse();
      const filteredDevices = devices.filter((item) => {
        if (item.deviceId === "default" && RTCManager.isMob()) {
          defaultDevices[item.kind] = item;
          return true;
        }
        if (item.deviceId === "default") {
          defaultDevices[item.kind] = item;
          return false;
        }
        return true;
      });

      filteredDevices.map((device) => {
        let item = { ...JSON.parse(JSON.stringify(device)) };
        if (!item.label) {
          return false;
        }
        if (item.kind === AUDIO_INPUT_DEVICES && this.inputDeviceChangeSupported) {
          if (
            (defaultDevices[AUDIO_INPUT_DEVICES] &&
              item.groupId === defaultDevices[AUDIO_INPUT_DEVICES].groupId) ||
            (item.deviceId === "default" && RTCManager.isMob())
          ) {
            if (sendDeviceList) {
              this.changeAudioSourceClicked(item.deviceId, filteredDevices);
            } else {
              this.setState({
                activeInputDevices: lastAudioDeviceId ? lastAudioDeviceId : item.deviceId,
              });
            }
            item.active = true;
          } else {
            item.active = false;
          }

          audioDevices.push(item);
        } else if (item.kind === VIDEO_INPUT_DEVICES) {
          item.active = activeVideoDeviceID === item.deviceId;
          videoDevices.push(item);
        } else if (defaultDevices[AUDIO_OUTPUT_DEVICES] && this.speakerChangeSupported) {
          if (item.groupId === defaultDevices[AUDIO_OUTPUT_DEVICES].groupId) {
            let lastAudioOutputDevice = localStorage.getItem("lastAudioOutputDevice");
            if (sendDeviceList) {
              this.changeAudioOutputDeviceClicked(
                lastAudioOutputDevice ? lastAudioOutputDevice : item.deviceId,
                filteredDevices
              );
            } else {
              this.setState({
                activeOutputDevices: lastAudioOutputDevice ? lastAudioOutputDevice : item.deviceId,
              });
            }
            if (!lastAudioOutputDevice)
              localStorage.setItem("lastAudioOutputDevice", item.deviceId);

            item.active = true;
          } else {
            item.active = false;
          }

          audioOutPutDevices.push(item);
        }
      });

      if (!defaultDevices[AUDIO_INPUT_DEVICES]) {
        const inputDevices = devices.filter((item) => item.kind === AUDIO_INPUT_DEVICES);
        if (inputDevices.length === 1) {
          this.setState({ activeInputDevices: inputDevices[0].deviceId });
        } else if (inputDevices.length > 1) {
          const currentDevices = Array.from(this.audioInputDevicesSet);
          this.setState({
            activeInputDevices: currentDevices[currentDevices.length - 1],
          });
        }
      }
      this.setState({ audioDevices, videoDevices, audioOutPutDevices });
      let exportDeviceListObj = {
        videoInputDevices: videoDevices,
        audioInputDevices: audioDevices,
        audioOutputDevices: audioOutPutDevices,
      };
      if (sendDeviceList) {
        if (this.props.triggerEvent) {
          this.props.triggerEvent(ON_DEVICE_CHANGE, exportDeviceListObj);
        }
      } else {
        if (this.props.triggerEvent) {
          this.props.triggerEvent(INITIAL_DEVICE_LIST, exportDeviceListObj);
        }
      }
    });
  };

  /**
   * called when user change the audio input device
   * @param {string}
   */
  changeAudioSourceClicked = debounce(async (id, audioDevicesN = null) => {
    if (sharedObject.isPresenter === false) return;
    const audioDevices = audioDevicesN ? audioDevicesN : this.state.audioDevices;
    let deviceIdNotExist = true;
    let updatedAudioInputList = audioDevices?.map((device) => {
      if (device.deviceId === id) {
        deviceIdNotExist = false;
        device.active = true;
      } else {
        device.active = false;
      }
      return device;
    });
    if (deviceIdNotExist) {
      return false;
    }
    localStorage.setItem("lastAudioDeviceId", id);
    console.log("custom lastAudioDeviceId", id);

    await this.disposeLocalAudioTrack();
    JitsiMeetJS.createLocalTracks({
      devices: [TRACK_AUDIO],
      micDeviceId: id,
    })
      .then((track) => {
        this.onLocalTracks(track, true);

        this.triggerOnDeviceChange({
          audioInputDevices: updatedAudioInputList,
        });
        this.setState({ activeInputDevices: id });
      })
      .catch((error) => {
        JitsiMeetJS.createLocalTracks({
          devices: [TRACK_AUDIO],
        }).then((track) => this.onLocalTracks(track, true));
      });
  }, 0);

  /**
   * called when user change the video input device
   * @param {string}
   */
  changeVideoSourceClicked = async (id) => {
    let deviceIdNotExist = true;
    this.state.videoDevices.map((device) => {
      if (device.deviceId === id) {
        deviceIdNotExist = false;
      }
    });
    if (deviceIdNotExist) {
      return false;
    }
    localStorage.setItem("lastVideoDeviceId", id);
    console.log("custom lastVideoDeviceId", id);
    if (this.presenterTrack) {
      await this.presenterTrack.dispose();
      await this.desktopVideoTrack.setEffect(
        await createPresenterEffect(await this.createLocalPresenterTrack(false, 400, id))
      );
      this.getDevices();
      return;
    }
    await this.disposeLocalVideoTrack();
    JitsiMeetJS.createLocalTracks({
      devices: [TRACK_VIDEO],
      cameraDeviceId: id,
    })

      .then((track) => {
        this.onLocalTracks(track, true);
        this.setState({
          appliedBg: 0,
          selectedBg: 0,
          addedBackgroundImage: null,
        });
        this.getDevices();
      })
      .catch((error) => {
        Logger.log(TAG, "changeVideoSourceClicked", "error", error);

        JitsiMeetJS.createLocalTracks({
          devices: [TRACK_VIDEO],
        }).then((track) => this.onLocalTracks(track, true));
      });
  };

  /**
   * called when user change the audio output device
   * @param {string}
   */
  changeAudioOutputDeviceClicked = (id, audioOuputDevicesN = null) => {
    const audioOuputDevices = audioOuputDevicesN
      ? audioOuputDevicesN
      : this.state.audioOutPutDevices;
    let deviceIdNotExist = true;
    let updatedAudioOutputDevices = audioOuputDevices?.map((device) => {
      if (device.deviceId === id) {
        deviceIdNotExist = false;
        device.active = true;
      } else {
        device.active = false;
      }
      return device;
    });
    if (deviceIdNotExist) {
      return false;
    }
    try {
      JitsiMeetJS.mediaDevices.setAudioOutputDevice(id);
      localStorage.setItem("lastAudioOutputDevice", id);
      this.setState({ activeOutputDevices: id }, () => {
        // this.getDevices();
      });

      this.triggerOnDeviceChange({
        audioOutputDevices: updatedAudioOutputDevices,
      });
    } catch (error) {
      Logger.log(TAG, "[changeAudioOutputDeviceClicked]", error);
    }
  };

  /**
   * called when user change UI Mode (GRID/SPOTLIGHT/TILE)
   * @param {string}
   */
  changeMode = (mode) => {
    if (mode === "GRID" && this.state.users?.length > 16) {
      return false;
    }
    this.setState({ mode, isGridMode: mode === "GRID" }, () => {
      this.initProps();
      if (this.props.triggerEvent) {
        this.props.triggerEvent(ON_MODE_CHANGE, this.state.mode);
      }
    });
  };

  /**
   * called when active audio video device is changed
   * its sends the new device list to chat SDK
   * @param {string}
   */
  triggerOnDeviceChange = (updatedList) => {
    let exportDeviceListObj = {
      videoInputDevices: this.state.videoDevices,
      audioInputDevices: this.state.audioDevices,
      audioOutputDevices: this.state.audioOutPutDevices,
      ...updatedList,
    };
    if (this.props.triggerEvent) {
      this.props.triggerEvent(ON_DEVICE_CHANGE, exportDeviceListObj);
    }
  };

  /**
   * Start to sream conferance on youtube
   * @requires sream key
   */
  startStreaming = (e) => {
    e.preventDefault();
    if (!this.state.streamingKey) {
      return false;
    }
    room
      .startRecording({
        mode: "stream",
        streamId: this.state.streamingKey,
        // streamId: "evzj-bcsp-cdbm-c5gy-4v3k",
      })
      .then((obj) => {
        Logger.log(TAG, "[startStreaming]", "sessionidrecord", obj);
        recordId = obj._sessionID;
        this.setState({ isStreaming: true });
      });
  };

  /**
   * Start to record conferance
   * recording are stored on S3 server
   */
  startRecording = () => {
    try {
      if (!this.state.isRecording) {
        room
          .startRecording({ mode: "file" })
          .then((obj) => {
            this.setState({ tryingToStartRecording: false });
            Logger.log(TAG, "[startRecording]", "success", obj);
            recordId = obj._sessionID;
            this.setState(
              {
                isRecording: true,
                recordId,
                recorder: this.state.users[0],
              },
              () => {
                room.sendCommandOnce(START_SCREEN_RECORDING, {
                  attributes: { recordId, ...this.state.recorder },
                });
                if (this.props.triggerEvent) {
                  this.props.triggerEvent(ON_RECORDING_TOGGLED, {
                    recordingStarted: true,
                    user: {
                      recordId,
                      ...this.removeAudioVideoTrackFromUser(this.state.users[0]),
                    },
                  });
                }
              }
            );
          })
          .catch((error) => {
            Logger.log(
              TAG,
              "[startRecording]",
              error,
              "trying to reconnect. try number:",
              this.recordingRetry
            );
            this.recordingRetryTimer = setTimeout(() => {
              this.startRecording();
            }, this.recordingRetry * 5000);
            this.setState({ tryingToStartRecording: true });

            if (this.recordingRetry === 1) {
              this.setState({ recorder: this.state.users[0] }, () => {
                room.sendCommandOnce(TRYING_TO_START_SCREEN_RECORDING, {
                  attributes: { ...this.state.users[0] },
                });
              });
            }
            this.recordingRetry++;
          });
      }
    } catch (error) {
      Logger.log(TAG, "[startRecording]", error);
    }
  };
  /**
   * Stops to record conferance
   */
  stopRecording = (forceFully = false) => {
    //alert("I stopped recording");
    if (this.state.tryingToStartRecording) {
      if (this.state.recorder.id === this.state.users[0].id || forceFully) {
        clearTimeout(this.recordingRetryTimer);
        this.recordingRetry = 1;
        this.setState({ tryingToStartRecording: false });
        room.sendCommandOnce(TRYING_TO_STOP_START_SCREEN_RECORDING, {});
        return;
      } else {
        if (sharedObject.isPresenter !== false) {
          this.props.enqueueSnackbar(this.localizedObject["STOP_RECORDING_ERROR"], {
            variant: "error",
          });
        }
      }
      return;
    }
    Logger.log(
      TAG,
      "stopRecording recording id = ",
      this.state.recordId,
      "rtcorder",
      this.state.recorder
    );

    if (this.state.isRecording && this.state.recorder) {
      if (this.state.recorder.id === this.state.users[0].id || forceFully) {
        room.sendCommand(STOP_SCREEN_RECORDING, {
          attributes: this.removeAudioVideoTrackFromUser(this.state.users[0]),
        });
        room.stopRecording(this.state.recordId);

        this.setState({
          isRecording: false,
          isStreaming: false,
          streamingKey: "",
          recordId: "",
          recorder: {},
        });
      } else {
        if (sharedObject.isPresenter !== false) {
          this.props.enqueueSnackbar(this.localizedObject["STOP_RECORDING_ERROR"], {
            variant: "error",
          });
        }
      }
    }
  };

  /**
   * Triggered when user clickes recording button
   * calls start.stopRecoding function based on isRecodingOn
   */
  recordingClicked = () => {
    if (this.state.isRecording || this.state.tryingToStartRecording) {
      this.stopRecording();
    } else {
      this.startRecording();
    }
  };

  peopleClicked = () => {
    this.setState({ showUserList: !this.state.showUserList });
  };

  getLastN() {
    if (
      this.state.mode == "SIDEBAR" ||
      !this.state.mode ||
      this.state.mode.toLowerCase() == "default"
    ) {
      return 5;
    } else if (this.state.mode == "TILE") {
      if (this.state.pinnedUserId) {
        return 5;
      }
      if (this.state.users.length > 35) {
        return 10;
      } else if (this.state.users.length > 25) {
        return 15;
      } else {
        return 20;
      }
    } else {
      return -1;
    }
  }

  /**
   * returns the UI for the MODE selected
   */
  renderVideoView = () => {
    const { users, dominantSpeakerId, pinnedUserId } = this.state;
    let dummyMainUser = users[0];
    if (dominantSpeakerId && !pinnedUserId) {
      dummyMainUser = users.find((user) => user.id === dominantSpeakerId);
    }
    if (pinnedUserId) {
      dummyMainUser = users.find((user) => user.id === pinnedUserId);
    }
    // if (dummyMainUser && dummyMainUser.id) {
    //   let selectParticipantId = dummyMainUser.id;
    //   if (selectParticipantId && room) {
    //     var constrains = {};
    //     constrains.lastN = -1;
    //     constrains.selectedEndpoints = [selectParticipantId];
    //     constrains.onStageEndpoints = [selectParticipantId];
    //     constrains.defaultConstraints = { maxHeight: 180 };
    //     constrains["constraints"] = {};
    //     constrains["constraints"][selectParticipantId] = { maxHeight: 720 };

    //     room.setReceiverConstraints(constrains);
    //   }
    // }
    switch (this.state.mode) {
      case "DEFAULT":
      case "SIDEBAR":
        if (dummyMainUser?.id) {
          let constraints = {};

          constraints[dummyMainUser.id] = { maxHeight: 720 };
          room.setReceiverConstraints({
            lastN: this.getLastN(),
            onStageEndpoints: [dummyMainUser.id],
            defaultConstraints: { maxHeight: 180 },
            constraints,
          });
        }
        return (
          <SideBar
            noisyMics={this.state.noisyMics}
            stats={this.stats}
            lastNId={this.state.LastNId}
            connectionQuality={this.state.connectionQuality}
            avatarMode={this.state.avatarMode}
            users={this.state.users}
            dominantSpeakerId={this.state.dominantSpeakerId}
            pinnedUserId={this.state.pinnedUserId}
            myScreenShareID={this.myScreenShareID}
            room={room}
            // resolutions={this.state.resolutions}
            pinUser={this.pinUser}
            muteUser={this.muteParticipant}
            unPinUser={this.unPinUser}
            peopleClicked={this.peopleClicked}
            localizedObject={this.localizedObject}
            mainUser={dummyMainUser || this.state.users[0]}
            localUserId={this.state.users[0].id}
            fitVideo={this.state.fitVideo}
            toggleFitVideo={this.toggleFitVideo}
          />
        );
      case CALLING_MODES.PRESENTER:
        if (dummyMainUser?.id) {
          let constraints = {};

          constraints[dummyMainUser.id] = { maxHeight: 720 };
          room.setReceiverConstraints({
            lastN: this.getLastN(),
            onStageEndpoints: [dummyMainUser.id],
            defaultConstraints: { maxHeight: 180 },
            constraints,
          });
        }
        let screenSharedUserIndex = -1;
        if (dummyMainUser?.screenShareUserID) {
          screenSharedUserIndex = this.state.users.findIndex(
            (item) => item.id === dummyMainUser.screenShareUserID
          );
        }

        return (
          <>
            <Presenter
              noisyMics={this.state.noisyMics}
              stats={this.stats}
              lastNId={this.state.LastNId}
              connectionQuality={this.state.connectionQuality}
              avatarMode={this.state.avatarMode}
              users={this.state.users}
              dominantSpeakerId={this.state.dominantSpeakerId}
              pinnedUserId={this.state.pinnedUserId}
              myScreenShareID={this.myScreenShareID}
              pinUser={this.pinUser}
              muteUser={this.muteParticipant}
              unPinUser={this.unPinUser}
              peopleClicked={this.peopleClicked}
              localizedObject={this.localizedObject}
              mainUser={dummyMainUser || this.state.users[0]}
              fitVideo={this.state.fitVideo}
              toggleFitVideo={this.toggleFitVideo}
              screenSharedUserIndex={screenSharedUserIndex}
            />
            {screenSharedUserIndex > -1 && (
              <LocalVideo
                user={this.state.users[screenSharedUserIndex]}
                boundaryClass={"side-bar-main-user-container"}
                avatarMode={this.state.avatarMode}
              />
            )}
          </>
        );

      case "SPOTLIGHT":
        if (dummyMainUser?.id) {
          let constraints = {};

          constraints[dummyMainUser.id] = { maxHeight: 720 };
          if (this.screenShareUserID) constraints[this.screenShareUserID] = { maxHeight: 180 };
          room.setReceiverConstraints({
            lastN: this.getLastN(),
            onStageEndpoints: [dummyMainUser.id],
            defaultConstraints: { maxHeight: 0 },
            constraints,
          });
        }
        let localVideoUserIndex = this.state.users.findIndex(
          (item) => item.id === this.screenShareUserID
        );
        return (
          <>
            <Spotlight
              noisyMics={this.state.noisyMics}
              stats={this.stats}
              connectionQuality={this.state.connectionQuality}
              avatarMode={this.state.avatarMode}
              users={this.state.users}
              mainUser={dummyMainUser || this.state.users[0]}
              dominantSpeakerId={this.state.dominantSpeakerId}
              pinnedUserId={this.state.pinnedUserId}
              myScreenShareID={this.myScreenShareID}
              screenShareUserID={this.screenShareUserID}
              room={room}
              unPinUser={this.unPinUser}
              pinUser={this.pinUser}
              muteUser={this.muteParticipant}
              fitVideo={this.state.fitVideo}
              toggleFitVideo={this.toggleFitVideo}
            />
            <LocalVideo
              user={
                this.state.users[
                  isScreenSharingTrack(dummyMainUser?.videoTrack) &&
                  this.screenShareUserID &&
                  this.props.screenShareMode !== "presenter"
                    ? localVideoUserIndex == -1
                      ? 0
                      : localVideoUserIndex
                    : 0
                ]
              }
              avatarMode={this.state.avatarMode}
            />
          </>
        );
      // case "TILE":
      //   let constraints = {};
      //   if (this.state.pinnedUserId) {
      //     constraints = { [pinnedUserId]: { maxHeight: 720 } };
      //   }
      //   room.setReceiverConstraints({
      //     lastN: this.getLastN(),

      //     onStageEndpoints: this.state.pinnedUserId ? [pinnedUserId] : [],
      //     defaultConstraints: { maxHeight: 180 },
      //     constraints,
      //   });
      //   return (
      //     <TileMode
      //       noisyMics={this.state.noisyMics}
      //       stats={this.stats}
      //       lastNId={this.state.LastNId}
      //       connectionQuality={this.state.connectionQuality}
      //       avatarMode={this.state.avatarMode}
      //       muteUser={this.muteParticipant}
      //       pinnedUserId={this.state.pinnedUserId}
      //       users={this.state.users}
      //       dominantSpeakerId={this.state.dominantSpeakerId}
      //       localUser={this.state.users[0].id}
      //       noOfUsersInTile={this.state.noOfUsersInTile}
      //       myScreenShareID={this.myScreenShareID}
      //       room={room}
      //       pinUser={this.pinUser}
      //       unPinUser={this.unPinUser}
      //       peopleClicked={this.peopleClicked}
      //       localizedObject={this.localizedObject}
      //     />
      //   );
      case "TILE":
        return (
          <Paginated
            selected={this.state.selected || 0}
            setSelected={(selected) => {
              this.setState({ selected });
            }}
            lastN={this.state.LastNId}
            noisyMics={this.state.noisyMics}
            connectionQuality={this.state.connectionQuality}
            avatarMode={this.state.avatarMode}
            localizedObject={this.localizedObject}
            users={this.state.users}
            dominantSpeakerId={this.state.dominantSpeakerId}
            dominantSpeakerList={this.state.dominantSpeakerList}
            pinnedUserId={this.state.pinnedUserId}
            screenShareUserID={this.screenShareUserID}
            screenShareMode={this.props.screenShareMode}
            pinUser={this.pinUser}
            muteUser={this.muteParticipant}
            unPinUser={this.unPinUser}
            myScreenShareID={this.myScreenShareID}
            localUserId={this.state.users[0].id}
            noOfUsersPerPage={this.state.noOfUsersPerPage || 3}
            screenSharersID={this.screenSharersID}
            fitVideo={this.state.fitVideo}
            toggleFitVideo={this.toggleFitVideo}
          />
        );
      default:
        if (dummyMainUser?.id) {
          let constraints = {};

          constraints[dummyMainUser.id] = { maxHeight: 720 };
          room.setReceiverConstraints({
            lastN: this.getLastN(),
            onStageEndpoints: [dummyMainUser.id],
            defaultConstraints: { maxHeight: 180 },
            constraints,
          });
        }
        return (
          <SideBar
            stats={this.stats}
            lastNId={this.state.LastNId}
            connectionQuality={this.state.connectionQuality}
            avatarMode={this.state.avatarMode}
            users={this.state.users}
            dominantSpeakerId={this.state.dominantSpeakerId}
            pinnedUserId={this.state.pinnedUserId}
            screenShareUserID={this.screenShareUserID}
            room={room}
            myScreenShareID={this.myScreenShareID}
            // resolutions={this.state.resolutions}
            pinUser={this.pinUser}
            muteUser={this.muteParticipant}
            unPinUser={this.unPinUser}
            peopleClicked={this.peopleClicked}
            localizedObject={this.localizedObject}
            mainUser={dummyMainUser || this.state.users[0]}
            localUserId={this.state.users[0].id}
            fitVideo={this.state.fitVideo}
            toggleFitVideo={this.toggleFitVideo}
          />
        );
    }
  };

  setNoOfUser = (noOfUsersInTile) => {
    this.setState({ noOfUsersInTile });
  };

  screenShareComponent = () => {
    return (
      <ScreenShare
        ref={(ref) => (this.ScreenShareRef = ref)}
        user={this.state.users[0]}
        sessionID={this.props.sessionID}
        domain={this.props.domain}
        MUC={"conference." + this.props.domain}
        focus={"focus." + this.props.domain}
        bosh={"https://xmpp." + this.props.domain + "/http-bind"}
        boshLegacy={"https://" + this.props.domain + "/http-bind"}
        webSocket={"wss://xmpp." + this.props.domain + "/xmpp-websocket"}
        onTrackCreated={() => {
          this.setState({ isScreenShareOn: true, screenShareLoader: true });
        }}
        onError={() => {
          this.setState({ isScreenShareOn: false, screenShareLoader: false });
        }}
        onSuccess={(id) => {
          this.screenShareUserID = id;
          this.myScreenShareID = id;
          this.userIdScreenShare = id;
          this.setState({ isScreenShareOn: true, screenShareLoader: false });
          if (this.props.triggerEvent) {
            this.props.triggerEvent(SCREEN_SHARE_STARTED, {});
          }
        }}
        onStopped={() => {
          this.myScreenShareID = null;
          room.sendCommand(SCREEN_SHARING_STOPPED, {
            value: this.userIdScreenShare,
          });
          this.setState({ isScreenShareOn: false, screenShareLoader: false });
          if (this.props.triggerEvent) {
            this.props.triggerEvent(SCREEN_SHARE_ENDED, {});
          }
        }}
      />
    );
  };

  onBackgroundEffectSelected = async (option) => {
    // const cameraDeviceId = localStorage.getItem("lastVideoDeviceId");
    // console.log('custom cameraDeviceId', cameraDeviceId);
    const options = {
      devices: [TRACK_VIDEO],
    };

    createLocalTracksfun(options)
      .then(async (track) => {
        let newtrack = await addBackgroundEffect({
          track: track[0],
          option,
        });
        if (!this.state.users[0]?.videoTrack || this.state.users[0].isVideoMuted) {
          newtrack?.mute();
        }

        // await room.removeTrack(this.state.users[0]?.videoTrack);
        // await this.state.users[0]?.videoTrack.dispose();
        // room.addTrack(newtrack);
        await room.replaceTrack(this.state.users[0].videoTrack, newtrack);
        await this.state.users[0].videoTrack.dispose();
        // await this.state.users[0].videoTrack.unmute()

        let users = [...this.state.users];
        if (users[0].isVideoMuted) {
          newtrack?.mute();
        }
        this.setState({
          users: this.state.users.map((user, i) =>
            i == 0 ? { ...user, videoTrack: newtrack } : user
          ),
          selectedBg: option.selectedIndex,
          appliedBg: option.selectedIndex,
        });
      })
      .catch((e) => {
        console.log("onBackgroundEffectSelected error", e);
      });
  };

  setSelectedBg = (selectedBg) => {
    this.setState({ selectedBg });
  };

  setIsBackgroundModalVisible = (isBackgroundModelVisible) => {
    this.setState({
      isBackgroundModelVisible,
    });
  };

  setBackgroundBlur = (blurValue) => {
    addBackgroundEffect({
      track: this.localUser.videoTrack,
      option: {
        type: "blur",
        blurValue,
      },
    });
    if (
      blurValue == 0 ||
      !blurValue ||
      (typeof blurValue !== "string" && typeof blurValue !== "number")
    )
      this.setState({
        appliedBg: 0,
        selectedBg: 0,
        addedBackgroundBlur: null,
      });
    else
      this.setState({
        addedBackgroundImage: null,
        addedBackgroundBlur: blurValue,
        appliedBg:
          this.props.EnforceBackgroundImage !== "" &&
          typeof this.props.EnforceBackgroundImage === "string" &&
          this.props.ShowDefaultImages &&
          this.props.SetImages.length !== 0
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 4
            : this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string" &&
              this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + 4
            : this.props.ShowDefaultImages && this.props.SetImages.length !== 0
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
            : this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + 3
            : this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string"
            ? 4
            : this.props.SetImages.length + 3,

        selectedBg:
          this.props.EnforceBackgroundImage !== "" &&
          typeof this.props.EnforceBackgroundImage === "string" &&
          this.props.ShowDefaultImages &&
          this.props.SetImages.length !== 0
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 4
            : this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string" &&
              this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + 4
            : this.props.ShowDefaultImages && this.props.SetImages.length !== 0
            ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
            : this.props.ShowDefaultImages
            ? defaultBackgroundImageUrls.length + 3
            : this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string"
            ? 4
            : this.props.SetImages.length + 3,
      });
  };

  validURL = (str) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  };

  setBackgroundImage = (url) => {
    try {
      if (typeof url === "string" && url !== "" && this.validURL(url)) {
        addBackgroundEffect({
          track: this.localUser.videoTrack,
          option: { type: "img", url },
        });
        this.setState({
          addedBackgroundBlur: null,
          addedBackgroundImage: url,
          appliedBg:
            this.props.EnforceBackgroundImage !== "" &&
            typeof this.props.EnforceBackgroundImage === "string" &&
            this.props.ShowDefaultImages &&
            this.props.SetImages.length !== 0
              ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 4
              : this.props.EnforceBackgroundImage !== "" &&
                typeof this.props.EnforceBackgroundImage === "string" &&
                this.props.ShowDefaultImages
              ? defaultBackgroundImageUrls.length + 4
              : this.props.ShowDefaultImages && this.props.SetImages.length !== 0
              ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
              : this.props.ShowDefaultImages
              ? defaultBackgroundImageUrls.length + 3
              : this.props.EnforceBackgroundImage !== "" &&
                typeof this.props.EnforceBackgroundImage === "string"
              ? 4
              : this.props.SetImages.length + 3,

          selectedBg:
            this.props.EnforceBackgroundImage !== "" &&
            typeof this.props.EnforceBackgroundImage === "string" &&
            this.props.ShowDefaultImages &&
            this.props.SetImages.length !== 0
              ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 4
              : this.props.EnforceBackgroundImage !== "" &&
                typeof this.props.EnforceBackgroundImage === "string" &&
                this.props.ShowDefaultImages
              ? defaultBackgroundImageUrls.length + 4
              : this.props.ShowDefaultImages && this.props.SetImages.length !== 0
              ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
              : this.props.ShowDefaultImages
              ? defaultBackgroundImageUrls.length + 3
              : this.props.EnforceBackgroundImage !== "" &&
                typeof this.props.EnforceBackgroundImage === "string"
              ? 4
              : this.props.SetImages.length + 3,
        });
      } else if (typeof url === "object") {
        getBase64(url).then((file) => {
          addBackgroundEffect({
            track: this.localUser.videoTrack,
            option: { type: "img", url: file },
          });
          this.setState({
            addedBackgroundBlur: null,
            addedBackgroundImage: file,
            appliedBg:
              this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string" &&
              this.props.ShowDefaultImages
                ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
                : this.props.ShowDefaultImages
                ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
                : this.props.SetImages.length + 3,

            selectedBg:
              this.props.EnforceBackgroundImage !== "" &&
              typeof this.props.EnforceBackgroundImage === "string" &&
              this.props.ShowDefaultImages
                ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
                : this.props.ShowDefaultImages
                ? defaultBackgroundImageUrls.length + this.props.SetImages.length + 3
                : this.props.SetImages.length + 3,
          });
        });
      } else {
        addBackgroundEffect({
          track: this.localUser.videoTrack,
          option: {},
        });
        this.setState({
          appliedBg: 0,
          selectedBg: 0,
          addedBackgroundImage: null,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    return (
      <div className='cc-main-container custom-main-container'>
        {isJoined ? (
          <>
            {this.state.isRecording ? (
              <div className='recording-text-container'>
                <FiberManualRecordIcon className={"main-container-recording-icon"} />
                <Typography className={"main-container-recording-text"} variant='h5'>
                  {this.localizedObject["RECORDING_TEXT"]}
                </Typography>
              </div>
            ) : this.state.tryingToStartRecording ? (
              <div className='recording-text-container'>
                <Typography className={"main-container-recording-text"} variant='h5'>
                  {this.localizedObject["TRYING_TO_START_RECORDING_TEXT"]}
                </Typography>
              </div>
            ) : this.state.requestingForVideoCall ? (
              <div className='recording-text-container'>
                <Typography className={"main-container-recording-text"} variant='h5'>
                  {this.localizedObject["REQUESTING_TO_SWITCH_VIDEO_CALL"]}
                </Typography>
              </div>
            ) : null}
            <div className='mainVideoContainer'>
              {this.screenShareComponent()}
              <div className={"layout"}>{this.renderVideoView()}</div>
              {this.state.showUserList ? (
                <UserList
                  noisyMics={this.state.noisyMics}
                  connectionQuality={this.state.connectionQuality}
                  isAudioOnly={this.state.isAudioOnly}
                  localizedObject={this.localizedObject}
                  kickUser={this.kickParticipant}
                  muteUser={this.muteParticipant}
                  pinnedUserId={this.state.pinnedUserId}
                  pinUser={this.pinUser}
                  unPinUser={this.unPinUser}
                  users={this.state.users}
                  close={this.peopleClicked}
                  recorderId={
                    this.state.recorder && this.state.recorder.id ? this.state.recorder.id : "id"
                  }
                />
              ) : null}
            </div>
            <BottomButtons
              showButtons={this.state.defaultLayout}
              showAudioMuteButton={this.props.showMuteAudioButton}
              toggleAudioButton={this.toggleAudioButton}
              user={this.state.users[0]}
              presenterTrack={this.presenterTrack}
              isPresenterTrackMuted={this.state.isPresenterTrackMuted}
              showVideoPauseButton={this.state.showVideoPauseButton}
              showEndCallButton={this.state.showEndCallButton}
              toggleVideoButton={this.toggleVideoButton}
              showScreenShareButton={this.state.showScreenShareButton && !this.state.isMob}
              onScreenShareClicked={this.onScreenShareClicked}
              isScreenShareOn={this.state.isScreenShareOn}
              isRecording={this.state.isRecording || this.state.tryingToStartRecording}
              ShowVirtualBackgroundSetting={this.props.ShowVirtualBackgroundSetting}
              showRecordingButton={this.props.showRecordingButton}
              recordingClicked={this.recordingClicked}
              onEndCallButtonCLicked={() => {
                this.props.triggerEvent(CALL_END_BUTTON_PRESSED, {});
                this.onEndCallButtonCLicked();
              }}
              showFullScreen={!this.state.isMob}
              showChangeModeButton={
                this.state.mode !== "SINGLE" && !this.state.isMob && this.props.showSwitchModeButton
              }
              mode={this.state.mode}
              changeMode={this.changeMode}
              activeInputDevices={this.state.activeInputDevices}
              activeOutputDevices={this.state.activeOutputDevices}
              changeAudioSourceClicked={this.changeAudioSourceClicked}
              changeVideoSourceClicked={this.changeVideoSourceClicked}
              changeAudioOutputDeviceClicked={this.changeAudioOutputDeviceClicked}
              audioOutPutDevices={this.state.audioOutPutDevices}
              audioDevices={this.state.audioDevices}
              videoDevices={this.state.videoDevices}
              peopleClicked={this.peopleClicked}
              noOfUsersInTile={this.state.noOfUsersInTile}
              setNoOfUsersInTile={this.setNoOfUser}
              usersLength={this.state.users.length}
              localizedObject={this.localizedObject}
              onBackgroundEffectSelected={this.onBackgroundEffectSelected}
              selectedBg={this.state.selectedBg}
              appliedBg={this.state.appliedBg}
              setSelectedBg={this.setSelectedBg}
              SetImages={this.props.SetImages}
              localVideoTrack={this.localUser.videoTrack}
              db={this.db}
              AllowUserImages={this.props.AllowUserImages}
              AllowBackgroundBlur={this.props.AllowBackgroundBlur}
              ShowDefaultImages={this.props.ShowDefaultImages}
              isBackgroundModelVisible={this.state.isBackgroundModelVisible}
              setIsBackgroundModalVisible={this.setIsBackgroundModalVisible}
              EnforceBackgroundImage={this.props.EnforceBackgroundImage}
              EnforceBackgroundBlur={this.props.EnforceBackgroundBlur}
              // onBackgroundEffectSelected={this.onBackgroundEffectSelected}
              isAudioOnly={this.state.isAudioOnly}
              isAnyScreenShareUser={this.state.isAnyScreenShareUser}
              addedBackgroundImage={this.state.addedBackgroundImage}
              addedBackgroundBlur={this.state.addedBackgroundBlur}
              setNoOfUsersPerPage={(n) => {
                console.log("changenumberOFUserPerPage", n);
                this.setState({ noOfUsersPerPage: n });
              }}
              noOfUsersPerPage={this.state.noOfUsersPerPage || 2}
            />
            {this.props.appID ? (
              <Analytics
                user={this.props.user}
                appID={this.props.appID}
                isAudioOnly={this.state.isAudioOnly}
                sessionID={this.props.sessionID}
                analyticsSettings={this.props.analyticsSettings}
              />
            ) : null}
          </>
        ) : (
          <div className={"loading-container"}>
            {!this.state.connectionError && <CircularProgress />}
            <Typography variant={"h4"} className={"loading-text"}>
              {this.state.connectionError
                ? this.localizedObject["CONNECTION_ERROR_TEXT"]
                : this.localizedObject["JOINING_TEXT"]}
            </Typography>
          </div>
        )}
        {(this.state.connectionLost || this.state.noNetwork) && (
          <div className={"reconnect-loading"}>
            <CircularProgress />
            <Typography variant={"h4"} className={"loading-text"}>
              {this.state.noNetwork
                ? this.localizedObject["NO_NETWORK_TEXT"]
                : this.localizedObject["RECONNECTING_TEXT"]}
            </Typography>
          </div>
        )}
        <RequestModal
          isModalVisible={this.state.toVideoRequestModal}
          name={this.state.toVideoRequestInitiator?.name}
          onClose={() => {
            console.log("closed");
            this.setState({ toVideoRequestModal: false });
          }}
          toVideoRequestType={this.state.toVideoRequestType}
          handleAccept={() => this.sendVideoRequestResponse(true)}
          handleReject={() => this.sendVideoRequestResponse(false)}
        />
        <RequestModalEndCall
          isModalVisible={this.state.endCallDialogVisible}
          handleLeave={() => this.endCall()}
          handleSessionEnd={() => this.endSessionForAll()}
        />
      </div>
    );
  }
}

export default withSnackbar(CallingComponent);
