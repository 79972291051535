import { SnackbarProvider } from "notistack";
import React, { Component } from "react";
import "./App.css";
// import "./assets/Graphik-Medium.ttf";
import "./assets/Inter-VariableFont_slnt,wght.ttf";
// import "@tensorflow/tfjs-backend-webgl";
import Slide from "@material-ui/core/Slide";
import { ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import CallingComponent from "./components/CallingComponent/CallingComponent";
import Logger from "./lib/Logger";
import Theme from "./components/CallingComponent/Utils/Theme";
import { LocalizedString, videoSettings } from "./Utils/Constants";
import "./index.css";
import "./styles/tailwind.generated.custom.css";
import { isDebugMode } from "./lib/utils";
import { sharedObject } from "./Utils/common";
const TAG = "CallingComponet";

const queryString = window.location.search;
const urlparams = new URLSearchParams(queryString);
var isBroadcast, isBroadcaster;
var showEndCallButton = true;
var showMuteAudioButton = true;
var showPauseVideoButton = true;
var defaultlayout = true;
var isAudioOnly = false;
var showScreenShareButton = true;
var showSwitchModeButton = true;
var showSwitchToVideoCallButton = true;
var startAudioMuted, startVideoMuted;
var region,
  sessionID,
  name,
  avatar,
  mode,
  uid,
  domain,
  analyticsSettings,
  localizedObject = LocalizedString,
  user,
  customCSS,
  showRecordingButton = true,
  startRecordingOnCallStart = false,
  appID,
  ShowVirtualBackgroundSetting = true,
  AllowBackgroundBlur = true,
  AllowUserImages = true,
  SetImages = [],
  ShowDefaultImages = true,
  EnforceBackgroundBlur = 0,
  EnforceBackgroundImage = "",
  avatarMode = "circle",
  screenShareMode = "default";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFullScreen: false,
      loadCallScreen: false,
    };
    this.callingComponentRef = React.createRef();
  }

  getCallingComponentRef() {
    return this.callingComponentRef;
  }

  componentDidMount() {
    // customCSS = null;
    // const $style = document.createElement("style");
    // document.head.appendChild($style);
    // $style.innerHTML = customCSS;
    if (isDebugMode) {
      sessionID = urlparams.get("sessionID") ?? "test";
      name = urlparams.get("username") ?? "test";
      mode = urlparams.get("mode") ?? "TILE";
      const rtcDomain = urlparams.get("domain");
      sharedObject.mode = mode;
      let isPresenter = urlparams.get("isPresenter");
      if (isPresenter === "true") {
        sharedObject.isPresenter = true;
      } else if (isPresenter === "false") {
        sharedObject.isPresenter = false;
      } else {
        sharedObject.isPresenter = undefined;
      }
      sharedObject.htmlElement = this.props.htmlElement;
      region = "us";
      domain = rtcDomain ?? "rtc-nonlive.cometchat-staging.com";
      uid = "uid";
      startAudioMuted = true;
      startVideoMuted = true;
      this.setState({ loadCallScreen: true });
    } else {
      this.addSettingListener();
    }
  }

  /**
   * addSettingListener will add a listener that will listen for the setting of the call
   */
  addSettingListener() {
    const settings = this.props.callsettings;
    screenShareMode = settings.screenShareMode || "default";
    showEndCallButton = settings.ShowEndCallButton;
    showRecordingButton = settings.ShowRecordingButton;
    startRecordingOnCallStart = settings.StartRecordingOnCallStart;
    showMuteAudioButton = settings.ShowMuteAudioButton;
    showPauseVideoButton = settings.ShowPauseVideoButton;
    showScreenShareButton = settings.ShowScreenShareButton;
    showSwitchToVideoCallButton = settings.ShowSwitchToVideoCallButton;
    defaultlayout = settings.defaultLayout;
    sessionID = settings.sessionID;
    region = settings.region.toLowerCase();
    isAudioOnly = settings.isAudioOnly;
    name = settings.user.name;
    avatar = settings.user.avatar;
    mode = settings.mode;
    sharedObject.mode = mode;
    sharedObject.isPresenter = settings.isPresenter;
    sharedObject.htmlElement = this.props.htmlElement;
    uid = settings.user.uid;
    if (typeof settings.localizedObject === "object")
      localizedObject = {
        ...LocalizedString,
        ...settings.localizedObject,
      };
    startAudioMuted = settings.StartAudioMuted;
    startVideoMuted = settings.StartVideoMuted;
    customCSS = settings.customCSS;
    user = settings.user;
    appID = settings.appId;
    analyticsSettings = settings.analyticsSettings;
    showSwitchModeButton = settings.ShowSwitchModeButton;
    ShowVirtualBackgroundSetting = settings.ShowVirtualBackgroundSetting;
    AllowBackgroundBlur = Boolean(settings.VirtualBackground?.AllowBackgroundBlur);
    AllowUserImages = Boolean(settings.VirtualBackground?.AllowUserImages);
    ShowDefaultImages = Boolean(settings.VirtualBackground?.ShowDefaultImages);
    SetImages = settings.VirtualBackground?.SetImages ? settings.VirtualBackground?.SetImages : [];
    EnforceBackgroundBlur = settings.VirtualBackground?.EnforceBackgroundBlur
      ? settings.VirtualBackground?.EnforceBackgroundBlur
      : 0;

    EnforceBackgroundImage = settings.VirtualBackground?.EnforceBackgroundImage
      ? settings.VirtualBackground?.EnforceBackgroundImage
      : "";
    avatarMode = settings.AvatarMode ? settings.AvatarMode : "circle";

    if ("MainVideoContainerSetting" in settings) {
      if (typeof settings.MainVideoContainerSetting?.videoFit === "string") {
        videoSettings.fit = settings.MainVideoContainerSetting.videoFit;
      }

      if (typeof settings.MainVideoContainerSetting?.zoomButton?.visibility === "boolean") {
        videoSettings.fitButton.visible = settings.MainVideoContainerSetting.zoomButton.visibility;
      } else if (typeof settings.MainVideoContainerSetting?.zoomButton?.visible === "boolean") {
        videoSettings.fitButton.visible = settings.MainVideoContainerSetting.zoomButton.visible;
      }

      if (typeof settings.MainVideoContainerSetting?.zoomButton?.position === "string") {
        videoSettings.fitButton.position = settings.MainVideoContainerSetting.zoomButton.position;
      }

      if (typeof settings.MainVideoContainerSetting?.nameLabel?.visibility === "boolean") {
        videoSettings.nameLabel.visible = settings.MainVideoContainerSetting.nameLabel.visibility;
      } else if (typeof settings.MainVideoContainerSetting?.nameLabel?.visible === "boolean") {
        videoSettings.nameLabel.visible = settings.MainVideoContainerSetting.nameLabel.visible;
      }

      if (typeof settings.MainVideoContainerSetting?.nameLabel?.position === "string") {
        videoSettings.nameLabel.position = settings.MainVideoContainerSetting.nameLabel.position;
      }

      if (typeof settings.MainVideoContainerSetting?.nameLabel?.color === "string") {
        videoSettings.nameLabel.background = settings.MainVideoContainerSetting.nameLabel.color;
      }

      if (typeof settings.MainVideoContainerSetting?.network?.visibility === "boolean") {
        videoSettings.network.visible = settings.MainVideoContainerSetting.network.visibility;
      } else if (typeof settings.MainVideoContainerSetting?.network?.visible === "boolean") {
        videoSettings.network.visible = settings.MainVideoContainerSetting.network.visible;
      }

      if (typeof settings.MainVideoContainerSetting?.network?.position === "string") {
        videoSettings.network.position = settings.MainVideoContainerSetting.network.position;
      }
    }

    if (urlparams.get("v") == 2) {
      domain = "rtc-" + region + ".cometchat.io";
    } else {
      domain = settings.domain;
    }

    this.setState({ loadCallScreen: true });
    const $style = document.createElement("style");
    document.head.appendChild($style);
    $style.innerHTML = customCSS;
  }

  render() {
    if (urlparams.get("broadcast") === 1) {
      isBroadcast = true;
    } else {
      isBroadcast = false;
    }
    const defaultTheme = {
      palette: {
        primaryIconColor: {
          main: "#1E79E7",
        },
        secondaryIconColor: {
          main: "#808080",
        },
        fontHeadingColor: {
          main: "#292A2c",
        },
        fontTitleColor: {
          main: "#292A2c",
        },
        fontSubTitleColor: {
          main: "#808080",
        },
        fontTextColor: {
          main: "#808080",
        },
        buttonBackgroundColor: {
          main: "#35383b",
        },
        error: {
          main: "#d93438",
        },
        primary: {
          main: "#1E79E7",
        },
      },
      typography: {
        fontFamily: "Inter",
        h3: {
          fontFamily: "Inter",
          fontSize: "2em",
          fontWeight: 900,
        },
        h4: {
          fontFamily: "Inter",
          fontSize: "1.5em",
          fontWeight: 700,
        },
        h5: {
          fontFamily: "Inter",
          fontSize: "1em",
          fontWeight: 400,
        },
        h6: {
          fontFamily: "Inter",
          fontSize: "0.7em",
          fontWeight: 400,
        },
      },
      spacing: (factor) => `${1 * factor}vh`,
      margin: (factor) => `${4 * factor}px`,
    };
    const theme = createTheme(defaultTheme);
    Theme.setTheme(defaultTheme);
    return (
      <div className='ccccn' id='cc-container'>
        <ThemeProvider theme={theme}>
          <div className='cc-App'>
            <SnackbarProvider
              autoHideDuration={6000}
              maxSnack={3}
              dense={true}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              TransitionComponent={Slide}
              classes={{
                containerRoot: "cc-snackbar-root-override",
              }}
            >
              {this.state.loadCallScreen ? (
                // <Fullscreen
                //   enabled={this.state.isFullScreen}
                //   onChange={(isFullScreen) => this.setState({ isFullScreen })}
                // >
                <div
                  className='cc-App-header'
                  id='fullscreen'
                  onDoubleClick={(e) => {
                    if (e.target.tagName === "BODY" || e.target.tagName === "DIV") {
                      this.setState({
                        isFullScreen: !this.state.isFullScreen,
                      });
                    }
                  }}
                >
                  <CallingComponent
                    ref={this.callingComponentRef}
                    triggerEvent={this.props.triggerEvent}
                    urlVersion={urlparams.get("v")}
                    screenShareMode={screenShareMode}
                    sessionID={sessionID}
                    username={name}
                    avatar={avatar}
                    region={region}
                    isBroadcast={isBroadcast}
                    isBroadcaster={isBroadcaster}
                    mode={mode}
                    uid={uid}
                    domain={domain}
                    isAudioOnly={isAudioOnly}
                    showEndCallButton={showEndCallButton}
                    showMuteAudioButton={showMuteAudioButton}
                    showPauseVideoButton={showPauseVideoButton}
                    showScreenShareButton={showScreenShareButton}
                    defaultLayout={defaultlayout}
                    localizedObject={localizedObject}
                    startAudioMuted={startAudioMuted}
                    startVideoMuted={startVideoMuted}
                    user={user}
                    appID={appID}
                    analyticsSettings={analyticsSettings}
                    showSwitchModeButton={showSwitchModeButton}
                    SetImages={SetImages}
                    showRecordingButton={showRecordingButton}
                    startRecordingOnCallStart={startRecordingOnCallStart}
                    AllowBackgroundBlur={AllowBackgroundBlur}
                    AllowUserImages={AllowUserImages}
                    ShowDefaultImages={ShowDefaultImages}
                    EnforceBackgroundImage={EnforceBackgroundImage}
                    EnforceBackgroundBlur={EnforceBackgroundBlur}
                    ShowVirtualBackgroundSetting={ShowVirtualBackgroundSetting}
                    showSwitchToVideoCallButton={showSwitchToVideoCallButton}
                    avatarMode={avatarMode}
                  />
                </div>
              ) : // </Fullscreen>
              null}
            </SnackbarProvider>
          </div>
        </ThemeProvider>
      </div>
    );
  }
}

export default App;
